import React, { useEffect, useMemo, useState } from 'react'
import ContainerAuthenticated from 'containers/Authenticated'
import CarouselApp from 'components/carousel'
import { FormContent, Load, LoadCenter } from 'ui/styled';
import { useParams } from 'react-router-dom';
import { BrandContent, BrandText, Title, TextContainer, LegacyText, LegacyContainer, LegacyImg, DrinkContainer, BrandImag } from './styled';
import Video from 'components/Video';
import History from 'components/History';
import ProductList from 'components/ProductList';
// import { drinks } from 'utils/drinks';
import PaymentMethods from 'components/PaymentMethods';
import Footer from 'components/Footer';
import { Read, ReadOne } from 'services/about-brands';
import { normalizeStrapiList, normalizeStrapiRegister, parseStrapiImage } from 'utils';
import { Read as ReadSkus } from "services/skus";
import BrandContentDetails from 'components/BrandContentDetails/JimBeam';
import JimBeam from 'components/BrandContentDetails/JimBeam';
import MakersMark from 'components/BrandContentDetails/MakersMark';
import Hos from 'components/BrandContentDetails/Hos';
import Laphroaing from 'components/BrandContentDetails/Laphroaig';
import Laphroaig from 'components/BrandContentDetails/Laphroaig';

export default function BrandDetails() {

  const { name } = useParams()

  const [brand, setBrand] = useState(null)
  const [relatedProducts, setRelatedProducts] = useState([])
  const [loading, setLoading] = useState(false)

  const newNavigate = link => window.location.href = link;


  const brandName = useMemo(() => {
    return name
      .replace(/[^\w\s]/gi, '')
      .replace('-', '')
      .toLowerCase();
  }, [name])

  const brandNameRender = useMemo(() => {
    return name.replace('-', ' ').replace(/\b\w/g, char => char.toUpperCase());
  }, [name])

  const productDetails = useMemo(() => {
    return {
      banner: [
        ...(brand?.banner || [])?.map((m) => ({
          url: parseStrapiImage(m?.url),
          alt: ' '
        }))
      ],
      title: brand?.title,
      description: brand?.description,
      // video: "/images/video.mp4", // Caminho para o vídeo
      video: parseStrapiImage(brand?.video?.url), // Caminho para o vídeo
      family_title: brand?.family_title,
      family_description: brand?.family_description,
      family_images: [
        ...(brand?.family_images || [])?.map((m) => parseStrapiImage(m?.url))
      ],
      product_links: brand?.product_links,
      banner_link: brand?.banner_link,
      history_title: brand?.hisotry_title,
      history_images: [

        ...(brand?.history_images || [])?.map((m) => ({
          year: m?.title,
          description: m?.description,
          image: parseStrapiImage(m?.image?.data?.attributes?.url)
        }))

      ],
      product_image: [
        ...(brand?.product_image || [])?.map((m) => ({
          url: parseStrapiImage(m?.url),
          alt: ' '
        }))
      ],
    }

  }, [brand])

  const init = async () => {

    if (name) {
      setLoading(true)
      const newName = name === 'Makers-Mark' ? "maker's-mark" : name
      const result = await ReadOne(newName)
      // const normalResult = normalizeStrapiRegister(result)
      setBrand(result[0])

      const result_sku = await ReadSkus()
      const normalResult_sku = normalizeStrapiList(result_sku)

      if (result?.[0]?.related_products?.length) {
        const parsedResult = result?.[0]?.related_products?.map(m => ({
          ...m, sku: normalResult_sku
            ?.find(f => f?.products?.data?.map(mm => mm.id)?.includes(m.id))?.id
        }))?.reduce((p, c) => (c?.sku && p?.map(m => m.sku)?.includes(c?.sku)) ? p : [...p, c], [])

        setRelatedProducts(parsedResult)
      }
      setLoading(false)
    }
  }

  useEffect(() => { init(); }, [name])

  const brandVideoSectionTitle = useMemo(() => {
    switch (brandName) {
      case 'jimbeam':
        return `${brandNameRender} ®`
      case 'makersmark':
        return 'O primeiro bourbon super premium do mundo'
      case 'hos':
        return 'A arte japonesa dos destilados'
      case 'laphroaig':
        return 'O sabor inigualável do autêntico single malt turfado'
      default:
        return `${brandNameRender} ®`
    }
  }
    , [brandName])

  useEffect(() => {
    init()
    window.scrollTo(0, 0)
  }, [name])

  const handleNavigate = () => {
    if (productDetails?.banner_link) {
      newNavigate(productDetails?.banner_link)
    }
  }

  return (
    <>
      <ContainerAuthenticated noHeader title={brand?.title}>
        {/* <CarouselApp imgs={productDetails.banner} /> */}
        {/* <FormContent noPadding> */}

        <BrandImag src={productDetails?.banner[0]?.url} onClick={handleNavigate} />
        {/* </FormContent> */}
        <BrandContent>
          <FormContent>
            <TextContainer>
              <Title brand={brandName}>
                {brandVideoSectionTitle}
              </Title>
              <BrandText>
                {productDetails.description}
              </BrandText>
            </TextContainer>
            {!productDetails.video ? null : <Video src={productDetails.video} />}
          </FormContent>
        </BrandContent>

        {brandName !== 'jimbeam' ? null : <JimBeam brand={brandName} productDetails={productDetails} />}
        {brandName !== 'makersmark' ? null : <MakersMark brand={brandName} productDetails={productDetails} />}
        {brandName !== 'thehouseofsuntory' ? null : <Hos brand={brandName} productDetails={productDetails} />}
        {brandName !== 'laphroaig' ? null : <Laphroaig brand={brandName} productDetails={productDetails} />}


        <DrinkContainer>
          <FormContent>
            {loading ? <LoadCenter> <Load black /></LoadCenter> : <CarouselApp imgs={productDetails.product_image} imgsLinks={productDetails?.product_links} />}
          </FormContent>
        </DrinkContainer>
        <ProductList title="Produtos relacionados" products={relatedProducts || []} />
        {/* <PaymentMethods /> */}
        <Footer />

      </ContainerAuthenticated>
    </>
  )
}
