import styled from "styled-components";

export const UniqueContainer = styled.div.attrs({
})`
  display: flex;
  flex-direction: column;
  min-width: 270px;
  z-index: 1;
  width: 100%;
  width: calc(100% / 4 - 16px);
  margin-bottom: 16px;

  @media (max-width: 768px) {
    min-width: 100%;
  }
`;

export const UniqueImg = styled.img.attrs({
})`
  width: 100%;
  height: auto;

`;

export const UniqueTitle = styled.div.attrs({
})`
  font-family: DM Serif Display;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: ${p => p.theme.palette.laphroaig.contraTitle};  
  text-transform: uppercase;
`;

