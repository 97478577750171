import { GET, POST, PUT, DELETE } from './api'

export const Create = async (params) => {
    return await POST(`/skus`, params);
}

export const Read = async () => {
    return await GET(`/skus?populate=*`);
}

export const ReadOne = async (id) => {
    return await GET(`/skus/${id}?populate=*`);
}

export const Update = async (params, id) => {
    return await PUT(`/skus/${id}`, params);
}

export const Delete = async (id) => {
    return await DELETE(`/skus/${ id }`);
} 
 