import React, { useEffect, useMemo, useState } from "react";
import {
    BrandsContainer,
    Container,
    FormContent,
    Img,
    ImgContainer
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import CarouselApp from "components/carousel";
import SuggestionsCard from "components/SuggestionsCard";
import Footer from "components/Footer";
import ProductList from "components/ProductList";
import { InpuntContainer } from "components/Dashboard/Header/styled";
import Input from "components/Form/Input";
import useWindowSize from "utils/useWindowSize";
import { drinks } from "utils/drinks";
import SuggestionNewsLetter from "components/SuggestionNewsLetter";
import PromotionCategory from "components/PromotionCategory";
import PaymentMethods from "components/PaymentMethods";
import AgeVerificationForm from "components/AgeVerificationForm";
import { Load, LoadCenter } from "ui/styled";
import { Read } from "services/home";
import { Read as ReadFaq } from "services/faq";
import { normalizeStrapiList, normalizeStrapiRegister, parseStrapiImage } from "utils";
import CommonQuestions from "components/CommonQuestions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function DashboardHome() {

    const history = useHistory();
    const navigate = to => history.push(`/${to}`);
    const newNavigate = link => window.location.href = link;

    const [loading, setLoading] = useState(false)
    const [faq, setFaq] = useState([])

    const [form, setForm] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : ''; }
    const changeForm = (value, ref) => { setForm({ ...form, [ref]: value }); }

    const [home, setHome] = useState(null);

    const imgs = useMemo(() => {
        return (home?.banners || [])?.map(m => ({
            alt: m?.name,
            url: parseStrapiImage(m?.url),
        }))
    }, [home])


    const cardData = []
    let b = [
        { title: "Oportunidades" },
        { title: "Frete grátis" },
        { title: "Dia dos Pais", color: "primary" },
        { title: "Kits", color: "primary" },
        { title: "Mês Bourbon", color: "secondary" },
    ];

    const brands = [
        { id: 1, src: '/icons/jim-beam.svg', alt: 'jim-beam', },
        { id: 2, src: '/icons/makers-mark.svg', alt: 'makers-mark', },
        { id: 4, src: '/icons/house-suntory.svg', alt: 'house-suntory', },
        { id: 5, src: '/icons/laphroaig.svg', alt: 'laphroaig' },
        { id: 3, src: '/icons/roku-gin.svg', alt: 'roku-gin', },
        { id: 6, src: '/icons/macallan.svg', alt: 'macallan', },
    ]



    const init = async () => {
        if (!loading) {
            setLoading(true)
            const result = await Read()
            const normalResult = normalizeStrapiRegister(result)

            setHome(normalResult)
            setLoading(false)
        }
    }

    useEffect(() => { init() }, [])


    return (
        <>
            <ContainerAuthenticated>
                {/* Render the age verification form only if ageActive is true */}
                <FormContent>

                    {(loading) ? <LoadCenter> <Load black /> </LoadCenter> : <></>}

                    <CarouselApp imgs={imgs} noControls imgsLinks={home?.banner_links} />
                    <Container>
                        <BrandsContainer>
                            {brands.map((brand, key) => (
                                <ImgContainer key={brand.id} onClick={() => home?.brands_links[key] && navigate(home?.brands_links[key])} >
                                    <Img src={brand?.src} alt={brand?.name} />
                                </ImgContainer>
                            ))}
                        </BrandsContainer>
                    </Container>

                    <SuggestionsCard cardData={cardData} />

                    <ProductList title="Os mais desejados" products={(home?.top_rateds || [])} />
                    <ProductList title="Os mais vendidos" products={(home?.top_sellers || [])} />
                    <SuggestionNewsLetter />
                    <PromotionCategory />
                    <CommonQuestions />
                    {/* <PaymentMethods /> */}
                    <Footer />

                </FormContent>
            </ContainerAuthenticated>
        </>
    );
}