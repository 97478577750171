import React from 'react'
import { FormBorder, FourWContainer, FourWImg, FourWText, FourWTitle, Subtitle, TextContainer, YearsText } from './styled'

export default function FourWCard({ item }) {
  return (
    <>
      <FourWContainer>
        <FourWImg src={item?.image} />
        <TextContainer>
          <FourWTitle bold>{item?.title}</FourWTitle>
          <Subtitle>{item?.subtitle}</Subtitle>
          <FourWText>{item?.description}</FourWText>
        </TextContainer>
      </FourWContainer>
    </>
  )
}
