import styled from "styled-components";


export const Image = styled.img.attrs({
})`
  width: 100%;
  max-width: 509px;
  height: auto;
`;

export const HistoryContainer = styled.div.attrs({
})`
  display: flex;
  justify-content: space-between;
  gap: 60px;
  flex-wrap: wrap;
  align-items: center;

`;
export const Subtitle = styled.div.attrs({
})`
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: ${p => p.theme.palette.colors.black};
  max-width: ${p => p.noMax ? 'none' : '609px'};

`;

export const TitleContainer = styled.div.attrs({
})`
  display: flex;
  flex-direction: column;
  gap: ${p => p.noSpace ? '0' : '32px'};
  max-width: 923px;
`;


export const PillarsImageContainer = styled.img`
  width: 100%;
  height: auto;
  margin-top: 90px;
`;

export const PillarsCardContainer = styled.div.attrs({
})`
  display: flex;
  gap: 24px;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 60px;
`;

export const PillarsContent = styled.div.attrs({
})`
  margin-top: 60px;
`;