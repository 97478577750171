import React from 'react'
import { BrandContainer, BrandTitle, FormContent } from 'ui/styled'
import { FourWCardContainer, FourWTitleContainer, SamuelsBackground, SamuelsCardContainer, SamuelsFamilyImage, Subtitle } from './styled'
import SamuelsCard from 'components/Cards/Samuels';
import FourWCard from 'components/Cards/Fourw';


export default function MakersMark({ brand, productDetails }) {
  const samuelsInfo = [
    {
      title: 'Bill Samuels Sr.',
      subtitle: 'O Criador Visionário',
      description: "Bill criou seu bourbon perfeito - Maker's Mark®. Começou como uma visão gustativa. Um verdadeiro artesao, ele reinventou não só o bourbon de sua família, mas também a indústria.",
      image: '/images/bsamuels.png'
    },
    {
      title: 'Margie Samuels',
      subtitle: 'A Visionária Criativa',
      description: 'Margie deu à marca sua visão criativa. O estilo, o design e a hospitalidade icônicos da marca se devem a ela. Ela fez a marca que hoje é reconhecida em todo o mundo.',
      image: '/images/margesamuels.png',
    },

  ];
  const fourwInfos = [
    {
      title: 'Wood',
      subtitle: 'Madeira',
      description: 'Cada barril é selecionado à mão e envelhecido a gosto, não ao tempo.',
      image: '/images/wood.jpg'
    },
    {
      title: 'Wax',
      subtitle: 'Cêra',
      description: "Cada garrafa Maker's Mark® ainda é mergulhada à mão. A cêra vermelha exclusiva é um sele para cada garrafa Maker's Mark®.",
      image: '/images/wax.jpg'
    },
    {
      title: 'Wheat',
      subtitle: 'Trigo',
      description: 'Ao contrário de outros uísques que usam centeio como grão de sabor, Maker’s Mark® usa trigo vermelho de inverno para um sabor mais suavee, doce.',
      image: '/images/wheat.png'
    },
    {
      title: 'Water',
      subtitle: 'Água',
      description: "Maker's Mark® é feito apenas com a água limpa e filtrada por calcário do nosso lago alimentado por nascentes.",
      image: '/images/water.png'
    }
  ]
  return (
    <>
      <BrandContainer brand={brand}>
        <FormContent>
          <BrandTitle brand={brand}>Como a família Samuels revolucionou a indústria do Bourbon</BrandTitle>
          <SamuelsCardContainer>
            <SamuelsBackground />
            {
              samuelsInfo?.map((item, index) => (
                <SamuelsCard key={index} item={item} />
              ))
            }
          </SamuelsCardContainer>
          <FourWTitleContainer>
            <BrandTitle brand={brand}>Os 4 W’s de Maker’s Mark</BrandTitle>
            <Subtitle>4 características compõem o tão amado Maker’s Mark, fazendo toda a diferença no processo de fabricação: Madeira, Cêra, Trigo e Água.</Subtitle>
          </FourWTitleContainer>
          <FourWCardContainer>

            {
              fourwInfos?.map((item, index) => (
                <FourWCard key={index} item={item} />
              ))
            }
          </FourWCardContainer>
        </FormContent>
      </BrandContainer>
    </>
  )
}

