import React from 'react'
import { FormBorder, SamuelsContainer, SamuelsImg, SamuelsText, SamuelsTitle, Subtitle, TextContainer, YearsText } from './styled'

export default function SamuelsCard({ item }) {
  return (
    <>
      <SamuelsContainer>
        <SamuelsImg src={item?.image} />
        <TextContainer>
          <SamuelsTitle bold>{item?.title}</SamuelsTitle>
          <Subtitle>{item?.subtitle}</Subtitle>
          <SamuelsText>{item?.description}</SamuelsText>
        </TextContainer>
      </SamuelsContainer>
    </>
  )
}
