import styled from "styled-components";

export const HistoryContainer = styled.div.attrs({
})`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: calc(100% / 3 - 27px);
  @media (max-width: 768px) {
    min-width: 100%;
  }
`;

export const HistoryImg = styled.img.attrs({
})`
  width: 100%;
  height: auto;
`;

export const YearsText = styled.div.attrs({
})`
  font-family: DM Serif Display;
  font-size: 32px;
  font-weight: 400;
  text-align: left;
  color: ${p => p.theme.palette.colors.black};
`;
export const FormBorder = styled.div.attrs({
})`
  width: 54px;
  height: 2px;
  background-color: ${p => p.theme.palette.colors.black};
`;

export const HistoryText = styled.div.attrs({
})`
  font-family: DM Sans;
  font-size: 18px;
  font-weight: ${p => p.bold ? '700' : '400'};
  line-height: 28px;
  text-align: left;
  color: ${p => p.theme.palette.colors.black};
`;