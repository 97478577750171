import { Icon, Img } from "ui/styled";
import { Content, Discount, FormContent, ImgContent, TitleProduct } from "./styled";
import Button from "components/Form/Button";
import { useHistory } from 'react-router-dom';
import ProductPrice from "components/ProductPrice";
import { parseDiscount } from "utils";

export default function ProductCard({ title, id, price, sale_price, image_link, paymentConditions, big }) {
  const history = useHistory();
  const navigate = to => history.push(`/${to}`);

  const handleNavigate = (name) => {
    const formattedName = title.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s+/g, '-')
    navigate(formattedName);
    window.scrollTo(0, 0);
  }

  const originalPrice = price
  const discount = parseDiscount(price, sale_price) ? `${parseDiscount(price, sale_price)}% OFF` : 0
  //  parseInt((100*(sale_price||0)) / (price||0)) > 0 ? `${100 - parseInt((100*(sale_price||0)) / (price||0))}% OFF` : 0
  const discountedPrice = sale_price ? sale_price : price
  const paymentDetails = ""

  return (
    <>
      <FormContent big={big}>
        <ImgContent>
          {discount ? <Discount>{discount}</Discount> : null}
          <Img src={image_link} big={big} />
        </ImgContent>
        <Content big={big}>
          <TitleProduct>{title}</TitleProduct>
          <ProductPrice originalPrice={originalPrice} discount={discount} discountedPrice={discountedPrice} paymentDetails={paymentDetails} paymentConditions={paymentConditions} navigate={navigate} id={id} />
          <Button primary noRadius onClick={handleNavigate}>
            Compre agora
            <Icon src="/icons/arrow-right.svg" />
          </Button>
        </Content>
      </FormContent>
    </>
  )
} 