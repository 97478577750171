import styled from "styled-components";

export const FooterContent = styled.div.attrs({

})`
    
    background-color: ${props => props.theme.palette.footerBackground.main};
    
 
`;
export const FormContent = styled.div.attrs({

})`
    display: flex;
    align-items: start;
    gap: 24px;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-around;
    padding: 80px 96px;
    max-width: 1512px;  
    margin: 0 auto;
  `;
export const Title = styled.h1.attrs({

})`
  font-family: DM Serif Display;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  max-width: 205px;
  text-align:center;
  margin-bottom: 16px;
  color: ${props => props.theme.palette.white.main};
`;
export const InfoFooterContent = styled.div.attrs({

})`
  max-width: 1512px;  
  margin: 0 auto;
  padding: 32px 96px;
  display: flex;
  justify-content: space-between;
  align-items: center; 


      padding: 16px;
      justify-content: center;
      flex-direction: column; 
`;
export const Text = styled.p.attrs({

})`
    font-family: DM Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align:center;
    color: ${props => props.theme.palette.white.main};
    cursor: pointer;
    &:hover {
    text-decoration: underline;
    }
  `;

export const InfoContent = styled.div.attrs({

})`
      display: flex;
      flex-direction: column;
  `;

export const ContentIcon = styled.div.attrs({

})`
    padding: 12px 0px;
      
`;

export const SocialContent = styled.div.attrs({

})`
    display: flex;
    gap: 12px;
    height: 38px;
    margin-top: 16px;
    justify-content: center;
  `;

export const InfoFooter = styled.div.attrs({

})`
  background-color: ${props => props.light ? props.theme.palette.colors.lightBackground : props.theme.palette.colors.black};
`;

export const InfoText = styled.div.attrs({

})`
    font-family: MetronicPro;
    font-size: 14px;
    font-weight: 400;
    line-height: 12px;
    text-align: left;
    color: ${props => props.light ? props.theme.palette.colors.black : props.theme.palette.white.main};

    
      text-align: center;
      margin-top: ${props => props.marginTop ? '16px' : '0'};
  `;

export const TextContainer = styled.div.attrs({

})`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-left: 16px;
`;

export const FooterContainer = styled.div.attrs({

})`
      display: flex;
  `;