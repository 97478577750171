import styled from "styled-components";

export const CommonQuestionsContent = styled.div.attrs({
})`

  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (max-width: 991px) {
    padding: 32px 24px;
  }
  `;
export const FaqsContainer = styled.div.attrs({
})`
  background-color: ${p => p.theme.palette.sugestionsGradient.secondary};  
  border-radius: 1px;

`;

export const Question = styled.div.attrs({
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 16px;
  cursor: pointer;
`;

export const Text = styled.div.attrs({
})`
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  font-family: DM Serif Display;
  color: ${props => props.theme.palette.colors.white};
`;

export const Answer = styled.div.attrs({
})`  
  background-color: ${props => props.faq ? props.theme.palette.colors.almostwhite : props.theme.palette.colors.question};
  padding: 0px 16px 16px;
`;
export const TextAnswer = styled.div.attrs({
})`  
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  text-align: left;
  color: ${props => props.theme.palette.colors.white};

`;

export const QuestionIcon = styled.img.attrs((props) => (({
  src: '/icons/chevron.svg'
})))`
    width: 18px;
    height: 18px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    ${props => props.open ? `transform: rotate(180deg);`
    : ``
  }
`;

export const CommonTitle = styled.div.attrs({
})`
  font-family: DM Serif Display;
  font-size: 24px;
  font-weight: 700;
  line-height: 48px;
  text-align: left;
  color: ${props => props.faq ? props.theme.palette.primary.main : props.theme.palette.colors.white};
  margin-bottom: 32px;
  display: flex;
  gap: 8px;
  align-items: flex-start;
`;

export const MoreInfosContainer = styled.div.attrs({
})`          
    display: flex;
    padding: 80px 96px;
    align-items: center;
    background: ${p => p.theme.palette.colors.backgroundgrey};
    max-width: 1512px;
    margin: 0 auto;
    justify-content: center;
    flex-direction: column;
    @media (max-width: 1145px){
      padding: 40px 24px;
    }

    @media (max-width: 991px){
      justify-content: center;    
      flex-wrap: wrap;
    }
`;

export const TitleContainer = styled.div.attrs({
})`
  width: 100%;
`;