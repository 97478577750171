import React, { useEffect, useMemo, useRef, useState } from "react";

import Header from 'components/Dashboard/Header'

import {
    DashboardPage,
    DashboardBody,
    DashboardBodyContent,
    Content,
    HeaderContainer
} from "./styled";
import { ReadObject } from "services/storage";
import { useHistory } from "react-router-dom";
import { ThemedComponent } from "ui/theme";
import AgeVerificationForm from "components/AgeVerificationForm";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import useAnalytics from "hooks/useAnalytics";


export default function ContainerAuthenticated({ children, noHeader, title }) {
    const { setPathPageView, setTrackEvent } = useTrackingCode();

    const { track } = useAnalytics();
    const location = useLocation();

    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const docTitle = useMemo(() => {
        return title ? `Prime Spirits Club | ${title}` : 'Prime Spirits Club';

    }, [title]);
    const init = () => {
        const authentication = ReadObject('authentication')
        if (!authentication?.jwt) {
            completeNext()
        }
    }

    const [ageActive, setAgeActive] = useState(true);

    const handleAgeActive = () => {
        setAgeActive(false);
    }

    const completeNext = () => {
        // navigate('login')
    }

    useEffect(() => {
        updateMetaTitle();
        setPathPageView();
        track('page_view', { page_path: location.pathname, page_title: docTitle });
    }, [location.pathname, title]);

    function updateMetaTitle() {
        document.title = docTitle;
    }


    useEffect(() => {
        init()
        window.scrollTo(0, 0)
    }, [location])

    return (
        <>
            <ThemedComponent>
                <Content>
                    <DashboardPage>
                        <HeaderContainer>
                            <Header />
                        </HeaderContainer>
                        <DashboardBody >
                            <DashboardBodyContent>
                                {children}
                            </DashboardBodyContent>
                        </DashboardBody>
                    </DashboardPage>
                </Content>
                {ageActive && <AgeVerificationForm handleAgeActive={handleAgeActive} />}
            </ThemedComponent>
        </>
    );
}