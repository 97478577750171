import React from 'react'
import { BrandContainer, BrandTitle, FormContent } from 'ui/styled'
import { HistoryContainer, Image, PillarsCardContainer, PillarsContent, PillarsImage, PillarsImageContainer, SamuelsFamilyImage, Subtitle, TitleContainer } from './styled'
import PillarsCard from 'components/Cards/Pillars';



export default function Hos({ brand, productDetails }) {
  const pullarsInfo = [
    {
      title: 'WA',
      description: 'Inspirado pela Harmonia com a natureza japonesa',
      image: '/images/wa.png'
    },
    {
      title: 'MONOZUKURI',
      description: 'Elevado pelo artesanato japonês',
      image: '/images/monozukuri.png',
    },
    {
      title: 'OMOTENASHI',
      description: 'Apreciado como autêntica hospitalidade Japonesa',
      image: '/images/omotenashi.png',
    }
  ];

  return (
    <>
      <BrandContainer brand={'white'} noBottom>
        <FormContent noBottom>
          <HistoryContainer>
            <TitleContainer>
              <BrandTitle brand={brand}>O legado de Shinjiro Torii</BrandTitle>
              <Subtitle>
                Em 1923, Shinjiro Torii vislumbrou um whisky repleto da essência da natureza japonesa e elaborado por artesãos através de um processo paciente de aprimoramento do trabalho da natureza. Ele alcançou esse sonho, e hoje, The House of Suntory é uma Casa de três gerações de artesãos japoneses inspirados pela natureza do Japão, criando destilados de classe mundial enraizados em um século de tradição.
              </Subtitle>
            </TitleContainer>
            <Image src={'/images/shinjiro.png'} alt="Shinjiro Torii" />
          </HistoryContainer>
          <PillarsContent>

            <TitleContainer noSpace>
              <BrandTitle brand={brand}>Os 3 pilares da Suntory</BrandTitle>
              <Subtitle noMax>
                Destilados criados em harmonia com a natureza (Wa), elevados por Monozukuri, e servidos no espírito de Omotenashi.
              </Subtitle>
            </TitleContainer>
            <PillarsCardContainer>
              {
                pullarsInfo.map((pillar, index) => (
                  <PillarsCard key={index} {...pillar} />
                ))
              }
            </PillarsCardContainer>
          </PillarsContent>
        </FormContent>
      </BrandContainer>
    </>
  )
}

