import React, { useContext, useMemo, useState } from "react";

import ContainerAuthenticated from "containers/Authenticated";
import useWindowSize from "utils/useWindowSize";


import { InputContente, CartProductsContent, Title, CartProductQuantity, ContentTitle, CartProductSummary } from './styled';
import Input from "components/Form/Input";
import Button from "components/Form/Button";
import { Icon } from "ui/styled";
import CartProductList from "components/CartProductList";
import PurchaseSummary from "components/PurchaseSummary";
import CheckoutContent from "components/CheckoutContent";
import { useHistory } from 'react-router-dom';
import ShippingDeadline from "components/SellerDetails/ShippingDeadline";
import { CoreContext } from "context/CoreContext";





export default function Cart() {
    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const { cart, setCart } = useContext(CoreContext)

    const total_quantity = useMemo(() => {
        return cart?.reduce((p, c) => p + (parseFloat(c?.quantity) || 1), 0) || 0
    }, [cart])

    const total_price = useMemo(() => {
        return parseFloat(cart?.reduce((p, c) => p + ((parseFloat(c?.quantity) || 1) * parseFloat(c?.price)), 0) || 0).toFixed(2)
    }, [cart])

    const buy = () => {
        const qs = cart?.map(m => `sc=1&sku=${m?.ref_id}&qty=${m?.quantity}&seller=1`)?.join('&')
        window.location.href = (`https://www.menimport.com.br/checkout/cart/add?${qs}`)
        setCart([])
    }

    return (
        <>
            <ContainerAuthenticated>
                <CheckoutContent title="Meu carrinho" tabType='Sacola'>
                    <InputContente>
                        {/* <ShippingDeadline full /> */}
                    </InputContente>
                    <CartProductSummary>
                        <CartProductsContent>
                            <ContentTitle>
                                <Title>Produtos no carrinho</Title>
                                <CartProductQuantity>{`${total_quantity} Produtos`}</CartProductQuantity>
                            </ContentTitle>
                            {
                                cart.map(item => (
                                    <CartProductList items={item} onChange={quantity => setCart(cart?.map(m => m?.id === item?.id ? { ...m, quantity } : m))} onRemove={() => setCart(cart?.filter(m => m?.id !== item?.id))} />
                                ))
                            }
                        </CartProductsContent>
                        <PurchaseSummary productsTotal={total_price} servicesTotal={0} grandTotal={total_price} qtd={total_quantity} onCheckout={buy} onContinueShopping={() => navigate("produtos")} />
                    </CartProductSummary>
                </CheckoutContent>
            </ContainerAuthenticated>
        </>
    );
}