import { GET, POST, PUT, DELETE } from './api'

export const Create = async (params) => {
  return await POST(`/departments`, params);
}

export const ReadDepartments = async () => {
  return await GET(`/departments?populate[0]=category_additionals.products`);
}

export const ReadOne = async (id) => {
  return await GET(`/departments/${id}?populate=*`);
}

export const Update = async (params, id) => {
  return await PUT(`/departments/${id}`, params);
}

export const Delete = async (id) => {
  return await DELETE(`/departments/${id}`);
}
