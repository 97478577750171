import styled from "styled-components";

export const Title = styled.div.attrs({
})`
  font-family: DM Serif Display;
  font-size: 48px;
  font-weight: 400;
  line-height: 80px;
  text-align: left;
  color: ${props => props.theme.palette[props.brand].main};
  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 48px;
  }

`;

export const LegacyContainer = styled.div.attrs({
})`
  margin: 64px 0;

`;

export const LegacyText = styled.div.attrs({
})`
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;

`;

export const LegacyImg = styled.img.attrs({
})`
  width: 100%;
`;
