import styled from 'styled-components'

export const FormContent = styled.div.attrs({
})` 

    font-family: DM Display;
    
    padding: 32px 96px;
    background-color: ${props => props.theme.palette.colors.backgroundgrey};
    max-width: 1440px;
    margin: 0 auto;
    font-family: DM Display;
    h2 {
        font-family: DM Serif Display;
        font-size: 24px;
        padding-top: 10px;
        padding-bottom: 6px;
    }
    p {
        font-family: DM Display;
        font-size: 16px;
        max-width: 80%;
    }
    @media (max-width: 600px) {
        padding: 32px 24px;
        p {
            max-width: 100%;
        
        }
    }
`;

export const ProductQuantity = styled.div.attrs({
})` 
    font-family: DM Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;

    color: ${props => props.theme.palette.colors.grey};
    margin-top: -5px;
`;

export const TitleContent = styled.div.attrs({
})` 
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 24px;

    font-family: DM Display;

    @media (max-width: 600px) {
        flex-direction: column;
        gap: 16px;
    }
`;
export const ButtonLink = styled.div.attrs({
})` 
  font-family: MetronicPro;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  text-decoration-line: underline;
  margin-bottom: 18px;
  width: 100px;
`;
export const BodyContent = styled.div.attrs({
})` 
    display: flex;
    gap: 40px; 
    @media (max-width: 600px) {
        flex-direction: column;
        gap: 0;
        justify-content: center;
    }   
`;

export const ProductContent = styled.div.attrs({
})` 
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  @media (max-width: 600px) {
    justify-content: center;
}
 
`;