import styled from "styled-components";

export const SamuelsContainer = styled.div.attrs({
})`
  display: flex;
  flex-direction: column;
  width: 380px;
  z-index: 1;
  margin-bottom: 16px;
  @media (max-width: 768px) {
    min-width: 100%;
  }
`;

export const SamuelsImg = styled.img.attrs({
})`
  width: 100%;
  height: auto;

`;

export const YearsText = styled.div.attrs({
})`
  font-family: DM Serif Display;
  font-size: 32px;
  font-weight: 400;
  text-align: left;
  color: ${p => p.theme.palette.colors.black};
`;
export const FormBorder = styled.div.attrs({
})`
  width: 54px;
  height: 2px;
  background-color: ${p => p.theme.palette.colors.black};
`;

export const SamuelsTitle = styled.div.attrs({
})`
  font-family: DM Serif Display;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: ${p => p.theme.palette.colors.black};

`;

export const Subtitle = styled.div.attrs({
})`
  font-family: DM Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  color: ${p => p.theme.palette.makersmark.main};
  font-style: italic;
`;

export const SamuelsText = styled.div.attrs({
})`
  font-family: DM Sans;
  font-size: 16px;
  font-weight: ${p => p.bold ? '700' : '400'};
  line-height: 28px;
  text-align: center;
  color: ${p => p.theme.palette.colors.black};
`;
export const TextContainer = styled.div.attrs({
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% + 1px);
  padding: 16px;
  background-image: url('/images/bloco1.png');
  background-size: cover;
  margin-top: -32px;
  margin-left: -1px;
`;