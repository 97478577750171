import styled from "styled-components";

export const BannerContent = styled.div.attrs({

})`
    padding:  12px 0px;
    background: ${props => props.theme.palette.colors.white};

 
`;

export const BannerImage = styled.div.attrs({
})`
    width: 100%; 
    object-fit: cover;
    background: url(${props => props.url}) no-repeat center center / contain;
    background-size: cover;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

`;

export const BannerProduct = styled.img.attrs({
})`
  width: 100%;
  opacity: 0;
`;
export const Content = styled.div.attrs({
})`
   max-width: 1512px;
   margin: 0 auto;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    position: relative;
  }

`;

export const BannerTitle = styled.div.attrs({
})`
  max-width: 640px;
  font-family: DM Serif Display;
  font-size: 44px;
  font-weight: 400;
  line-height: 72px;
  text-align: center;
  color: ${props => props.theme.palette.colors.white};
  @media (max-width: 768px) {
    font-size: 26px;
    line-height: 32px;
    padding: 0 16px;
  }

`;