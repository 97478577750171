import React from 'react'
import { FormContent } from 'ui/styled'
import { LegacyContainer, LegacyImg, LegacyText, Title } from './styled'
import History from 'components/History'

export default function JimBeam({ brand, productDetails }) {

  return (
    <>
      <FormContent>
        <Title brand={brand}>{productDetails.family_title}</Title>
        <LegacyText>
          {productDetails.family_description}
        </LegacyText>
        <LegacyContainer>
          {
            productDetails.family_images.map((image, index) => (
              <LegacyImg key={index} src={image} />
            ))
          }
        </LegacyContainer>
      </FormContent>
      <History history_images={productDetails.history_images} history_title={productDetails.history_title} brand={brand} />
    </>
  )
}
