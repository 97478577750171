import styled from "styled-components";


export const Image = styled.img.attrs({
})`
  width: 100%;
  height: auto;
`;


export const Title = styled.div.attrs({
})`
  font-family: DM Serif Display;
  font-size: 48px;
  font-weight: 400;
  line-height: 80px;
  text-align: left;
  color: ${props => props.theme.palette.colors.black};
  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 48px;s
  }
`;

export const Subtitle = styled.div.attrs({
})`
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: ${p => p.theme.palette.colors.black};
  max-width: ${p => p.noMax ? 'none' : '609px'};

`;

export const TitleContainer = styled.div.attrs({
})`
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 923px;
`;

export const PillarsImageContainer = styled.img`
  width: 100%;
  height: auto;
  margin-top: 90px;
`;

export const IslayContainer = styled.div.attrs({
})`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const HistoryContainer = styled.div.attrs({
})`
  width: 100%;
  background-color: ${props => props.theme.palette.colors.white};
  padding-bottom: 40px;

`;

export const HistoryCardContainer = styled.div.attrs({
})`
  display: flex;
  justify-content: space-between;
  gap: 40px;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 89px;

  @media (max-width: 768px) {
    margin-top: 40px;
  } 
`;

export const FormSpace = styled.div.attrs({
})`
  height: 90px;
  @media (max-width: 768px) {
    height: 40px;
  }
`;

export const UniqueCardContainer = styled.div.attrs({
})`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  flex-wrap: wrap;
  align-items: flex-end;
  @media (max-width: 768px) {
    margin-top: 40px;
  } 
`;