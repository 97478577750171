import React from 'react'
import { FormBorder, UniqueContainer, UniqueImg, UniqueTitle, Subtitle, TextContainer, YearsText } from './styled'

export default function UniqueCard({ item }) {
  return (
    <UniqueContainer>
      <UniqueTitle
        dangerouslySetInnerHTML={{ __html: item?.title }}
      />
      <UniqueImg src={item?.image} />
    </UniqueContainer>
  )
}