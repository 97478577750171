import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Carousel from 'react-bootstrap/Carousel';
import { CustomCarousel, Icon, Img, CarouselControls } from './styled';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function CarouselApp({ imgs, pdp, noControls = false, imgsLinks }) {
  const history = useHistory();
  const navigate = to => history.push(`/${to}`);

  const newNavigate = link => window.location.href = link;

  const [activeIndex, setActiveIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setActiveIndex(selectedIndex);
  };
  console.log(imgs);

  const handleNavigate = (index) => {
    if (imgsLinks && imgsLinks[index]) {
      newNavigate(imgsLinks[index]);
    } else {
      navigate('produtos');
    }
  }

  return !imgs?.length || !(typeof imgs?.map === 'function') ? null : (
    <>
      {!pdp ? null :
        imgs.map((img, index) => (
          <Img
            key={index}
            onClick={() => navigate('produtos')}
            src={img.url}
            alt={img.alt}
          />
        ))
      }

      {!!pdp ? null : (
        <>
          {/* Renderiza o carrossel com base na propriedade noControls */}
          <CustomCarousel
            activeIndex={activeIndex}
            onSelect={handleSelect}
            indicators={true}
            controls={noControls}
            nextIcon={noControls && <Icon src='/icons/next.svg' />}
            prevIcon={noControls && <Icon src='/icons/back.svg' />}
          >
            {imgs.map((img, index) => (
              <Carousel.Item key={index}>
                <Img
                  onClick={() => handleNavigate(index)}
                  src={img.url}
                  alt={img.alt}
                />
              </Carousel.Item>
            ))}
          </CustomCarousel>

          {/* Renderiza os controles customizados no final da página se noControls for verdadeiro */}
          {!noControls && (
            <CarouselControls>
              <Icon src='/icons/back.svg' onClick={() => setActiveIndex(activeIndex === 0 ? imgs.length - 1 : activeIndex - 1)} />
              <Icon src='/icons/next.svg' onClick={() => setActiveIndex(activeIndex === imgs.length - 1 ? 0 : activeIndex + 1)} />
            </CarouselControls>
          )}
        </>
      )}
    </>
  );
}