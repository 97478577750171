import styled from "styled-components";
import Carousel from 'react-bootstrap/Carousel';

export const Img = styled.img.attrs({})`
  cursor: pointer;
  width: 100%;
`;

export const CustomCarousel = styled(Carousel)`
  .carousel-indicators {
    display: flex;
    justify-content: center;
  }

  .carousel-indicators button {
    width: 8px;
    height: 8px;
    background-color: grey;
    border-radius: 50%;
    cursor: pointer;
    margin: 0 5px;
  }

  .carousel-indicators .active {
    background-color: ${props => props.theme.palette.colors.white};
  }

  @media (max-width: 600px) {
    .carousel-control-next,
    .carousel-control-prev {
      display: none;
    }
  } 
`;

export const Icon = styled.img.attrs({})`
  cursor: pointer;
  width: 50px; 
  margin: 0 10px;
`;

export const CarouselControls = styled.div.attrs({})`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;