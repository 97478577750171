import React from 'react'
import { PillarsDescription, PillarsImageContainer, PillarsTitle } from './styled'

export default function PillarsCard({ title, description, image }) {
  return (
    <>
      <PillarsImageContainer image={image}>
        <PillarsTitle>{title}</PillarsTitle>
        <PillarsDescription>{description}</PillarsDescription>

      </PillarsImageContainer>
    </>
  )
}
