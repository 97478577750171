import styled from 'styled-components';

export const Container = styled.div.attrs({
})`
  max-width: 676px;
  margin: 0 auto;
  padding: 40px;
  background-color: white;

  text-align: center;
  position: fixed;
  z-index: 1000;
  @media (max-width: 600px) {
    width: 100%;
    height: 100%;
    padding: 16px;
    overflow: auto;
  }
`;

export const Title = styled.h1.attrs({
})`
  font-family: DM Serif Display;
  font-size: 28px;
  font-weight: 400;
  line-height: 48px;
  text-align: center;
  margin-top: 28px;


`;

export const Subtitle = styled.p.attrs({
})`
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;

`;

export const FormRow = styled.div.attrs({
})`
  display: flex;
  gap: 24px;
  margin-bottom: 20px;
  flex-wrap: wrap;
  @media (max-width: 600px) {
    gap: 8px;
    justify-content: center;
  }
`;

export const CheckboxContainer = styled.div`

  background-color:${props => props.theme.palette.colors.backgroundgrey};
  padding-left: 8px;
`;

export const Checkbox = styled.input`
  margin-right: 10px;
`;

export const Label = styled.label`
  font-size: 14px;
`;

export const Disclaimer = styled.div`
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.83px;
  text-align: left;
  background-color: ${props => props.theme.palette.colors.backgroundgrey};
  margin-top: 20px;
  padding: 8px;
  margin-bottom: 14px;

`;

export const Overlay = styled.div.attrs({
})`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);  // Semi-transparent overlay
  z-index: 998;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 600px) {
    display: ${props => (props.active ? 'block' : 'none')}; // Show overlay when active
  }
`;

export const InputContainer = styled.div.attrs({
})`
  display: flex;
  gap: 24px;
  width: 100%;
  margin-bottom: 20px;
`;