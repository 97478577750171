import React from 'react'
import { DrinksCarouselContent, DrinksCarouselTitle, FormSpace } from './styled'
import { FormContent } from 'ui/styled'
import CarouselApp from 'components/carousel';

export default function DrinksCarousel({ images, pdp }) { 
 
  return (
    <>
      <DrinksCarouselContent>
        <FormContent>
          <DrinksCarouselTitle>Drinks</DrinksCarouselTitle>
          <CarouselApp imgs={images} pdp={pdp} />
          <FormSpace />
        </FormContent>
      </DrinksCarouselContent>
    </>
  )
}
