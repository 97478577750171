import React, { useEffect, useMemo, useState } from "react";
import {
    BodyContent,
    ButtonLink,
    FormContent,
    IframeTerms,
    ProductContent,
    ProductQuantity,
    TitleContent
} from "./styled";
import ContainerAuthenticated from "containers/Authenticated";
import Footer from "components/Footer";
import { Content, Load, LoadCenter, Title } from "ui/styled";
import Select from "components/Form/Select";
import SideBar from "components/SideBar";
import ProductCard from "components/ProductCard";
import { sortOptions } from "utils/options";
import { drinks } from "utils/drinks";
import PaymentMethods from "components/PaymentMethods";
import Brands from "components/Brands";
import useWindowSize from "utils/useWindowSize";
import { Read } from "services/products";
import { normalizeStrapiList } from "utils";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

export default function Terms() {
    const location = useLocation();

    const [loading, setLoading] = useState(false)
    
    const contactEmail = "contato@spiritsclub.com.br"

    return (
        <>
            <ContainerAuthenticated>
                <FormContent>


                    { ( loading ) ? <LoadCenter> <Load black /> </LoadCenter> : <></> }


                    
                    <h1>Aviso de Privacidade</h1>
                    <p>Data de vigência: 1º de janeiro de 2023</p>
                    <p>Última atualização: 25 de Agosto de 2024</p>

                    <h2>Introdução</h2>
                    <p>Este Aviso de Privacidade define como a Beam Suntory Inc. e suas subsidiárias, controladoras e empresas afiliadas e aqueles que agem em nosso nome ("Beam Suntory"; "nós"; "nos"; “nosso(a)(s)"; "conosco") utilizam informações suas durante a operação de nossos negócios e define seus direitos legais em relação às informações que tratamos sobre você, inclusive por meio de nossos sites, aplicativos e outros serviços. Nós podemos utilizar dados pessoais que nos foram fornecidos por você ou por outras partes para qualquer uma das finalidades descritas neste Aviso de Privacidade, ou conforme declarado de outra forma no momento da coleta.</p>
                    <p>Nós poderemos modificar este Aviso de Privacidade de tempos em tempos. Nós notificaremos você sobre possíveis alterações significativas neste Aviso de Privacidade, conforme exigido por lei. Também publicaremos uma cópia atualizada em nosso site. Você deverá verificar periodicamente se há alterações.</p>

                    <h2>Idade legal para o consumo de álcool e dados de crianças</h2>
                    <p>Este site não se destina a pessoas abaixo da idade legal para o consumo de bebidas alcoólicas e nós realizamos verificações de idade quando você visita o site (sem armazenar sua data de nascimento). Nós não coletamos nem solicitamos intencionalmente dados pessoais de indivíduos com idade inferior à idade legal para o consumo de bebidas alcoólicas. Se descobrirmos posteriormente que um indivíduo abaixo da idade legal para o consumo de bebidas alcoólicas nos forneceu dados pessoais, nós excluiremos esses dados o mais rápido possível. Não compartilhe nem encaminhe este site, ou seu conteúdo, para menores de idade. Se você acredita que uma criança abaixo da idade legal para o consumo de bebidas alcoólicas possa ter fornecido dados pessoais para nós, entre em contato conosco pelo e-mail <a href="mailto:privacy@beamsuntory.com">privacy@beamsuntory.com</a>.</p>

                    <h2>Links para outros sites</h2>
                    <p>Este Aviso de Privacidade não se aplica às práticas de privacidade de sites de terceiros vinculados a este site. Se você clicar em um link de terceiros, será levado a um site que não controlamos. Recomendamos que você leia as declarações de privacidade de outros sites que visitar. Não somos responsáveis pelas práticas dessas outras partes.</p>

                    <h2>O que queremos dizer com dados pessoais?</h2>
                    <p>Dados pessoais, por vezes denominados "informações pessoais", são informações relacionadas a uma pessoa natural identificada ou identificável e incluem todas as informações que identificam, relacionam-se, descrevem, fazem referência, podem ser associadas ou podem ser razoavelmente vinculadas, direta ou indiretamente, a você ou à sua família. Quando "você", "seu(s)" ou "sua(s)" são usados neste Aviso de Privacidade, estamos nos referindo ao indivíduo relevante que é titular dos dados pessoais.</p>

                    <h2>As informações que coletamos sobre você</h2>
                    <p>Podemos coletar várias categorias diferentes de dados pessoais de e sobre você:</p>
                    <ul>
                        <li>Dados de identificação, tais como nome, título, estado civil, data de nascimento ou faixa etária e gênero.</li>
                        <li>Dados de contato, tais como endereço para fatura, endereço de entrega, endereço de e-mail e números de telefone.</li>
                        <li>Dados financeiros, tais como dados de contas bancárias e cartões de pagamento.</li>
                        <li>Dados de transações, tais como detalhes sobre pagamentos on-line e off-line feitos por você; registros de produtos e serviços que você adquiriu / pediu de nós, incluindo visitas a destilarias; e informações coletadas por meio de carteiras digitais.</li>
                        <li>Dados de login, tais como suas credenciais de usuário e senha para qualquer conta de consumidor ou de suporte.</li>
                        <li>Dados de dispositivos e uso, incluindo informações sobre como você usa nosso site, produtos, ativos digitais, aplicativos e serviços, tais como informações de cookies, endereço de protocolo de Internet (IP), identificadores pessoais exclusivos (incluindo aqueles afiliados ao endereço da sua carteira digital), tipo e versão do navegador, fuso horário aplicável e geolocalização, tipos e versões de plug-ins de navegador, IDs de dispositivos móveis e MAID (Mobile Advertising IDs), sistema operacional e plataforma e outras tecnologias empregadas pelos dispositivos que você usa para acessar este site.</li>
                        <li>Dados de marketing e comunicação, incluindo suas preferências quanto ao recebimento de materiais de marketing, promoções e/ou newsletters de nossa parte e suas preferências de comunicação, respostas a pesquisas de satisfação do cliente ou pesquisas de mercado, feedback ou reclamações que você nos tenha comunicado.</li>
                        <li>Dados fotográficos e de vídeo, incluindo filmagens de CFTV de visitas a destilarias, fotos e vídeos feitos em nossos eventos, fotos ou vídeos que você carrega em nosso site ou sites de mídias sociais.</li>
                        <li>Dados de mídias sociais, incluindo interações em mídias sociais, uso, identificadores, foto de perfil e outras informações de perfis de mídias sociais (tais como o número de seguidores).</li>
                        <li>Conteúdo gerado pelo usuário, incluindo texto, imagens, áudios, vídeos, depoimentos, tweets, postagens em blogs e avaliações publicadas por você em plataformas on-line, tais como mídias sociais e sites de comércio eletrônico.</li>
                        <li>Dados entre empresas ("B2B"), incluindo informações sobre nossos relacionamentos entre empresas e com fornecedores, tais como informações de contato comercial fornecidas a nós on-line ou off-line para consumar o relacionamento e conduzir funções comerciais. Exemplos incluem seu nome, cargo, empresa em que trabalha, endereço, endereço de e-mail e número de telefone.</li>
                    </ul>
                    <p>Além disso, outros tipos específicos de informações podem ser coletados em associação a concursos, sorteios, ofertas promocionais ou outras iniciativas de marketing. Isso pode incluir suas preferências alimentares e de sabores. Também podemos coletar informações agregadas ou desidentificadas que não possam ser razoavelmente usadas para identificar você.</p>


                    <h2>Quando coletamos seus dados pessoais?</h2>
                    <p>Nós coletamos dados pessoais sobre você principalmente por meio de interações com você e do uso feito por você de nosso site e de todos os outros sites da família Beam Suntory e plataformas digitais. Além disso, este Aviso de Privacidade se aplica ao uso de qualquer informação sobre você que possamos coletar de você por meio de comunicação presencial, correio, telefone, e-mail, mídias sociais, inscrição/participação em eventos ou qualquer outro meio de comunicação (incluindo qualquer forma de comunicação eletrônica).</p>
                    <p>Podemos coletar informações que você nos fornece on-line ou off-line quando estabelecemos um relacionamento B2B, para a finalidade de consumar o relacionamento e conduzir funções comerciais.</p>
                    <p>Também coletamos dados técnicos derivados de sua navegação. Se você quiser mais informações sobre isso, consulte nossa Política de Cookies.</p>
                    <p>Além disso, podemos coletar informações sobre você a partir de outras empresas de nosso grupo corporativo, de nossos parceiros comerciais e de vários outros prestadores de serviços terceirizados.</p>

                    <h2>Como usamos seus dados pessoais?</h2>
                    <p>Seus dados pessoais podem ser usados para as seguintes finalidades:</p>
                    <ul>
                        <li>Para fornecer produtos e serviços a você.</li>
                        <li>Para comercializar e promover nossos produtos e serviços.</li>
                        <li>Para fornecer propaganda com base em interesses.</li>
                        <li>Para melhorar e desenvolver nossos negócios e produtos.</li>
                        <li>Para fornecer e administrar nossos programas de fidelidade.</li>
                        <li>Para monitorar e analisar tendências, uso e atividades relacionadas aos nossos produtos e serviços.</li>
                        <li>Suporte ao consumidor.</li>
                        <li>Administração do site.</li>
                        <li>Melhor experiência do usuário.</li>
                        <li>Para conduzir funções B2B.</li>
                        <li>Para cumprir com a lei ou regulamentos.</li>
                        <li>Para administrar e fornecer recursos digitais aos participantes.</li>
                    </ul>

                    <h2>Por quanto tempo mantemos seus dados?</h2>
                    <p>Os dados pessoais tratados por nós são retidos pelo tempo que for considerado necessário para a finalidade para a qual foram coletados, inclusive conforme exigido pela legislação ou regulamentação aplicável e para atender a requisitos legais, contábeis ou de relatórios, conforme necessário. Quando os dados deixarem de ser necessários para a finalidade para a qual foram coletados, nós os excluiremos com segurança ou, em alguns casos, eles serão anonimizados por nós.</p>

                    <h2>Com quem compartilhamos seus dados?</h2>
                    <p>Podemos compartilhar seus dados pessoais com subsidiárias da Beam Suntory Inc. e membros de nosso grupo corporativo, cuja empresa controladora é a Suntory Holdings Limited, quando tivermos uma base legal para fazer isso. Você pode acessar nosso site corporativo para saber mais. Também podemos compartilhar seus dados pessoais com outras partes mediante seu consentimento ou sob sua orientação.</p>
                    <p>Talvez precisemos compartilhar suas informações com certas partes específicas que prestam serviços para nós ou agem em nosso nome em associação à operação de nossos negócios. Nós exigimos de tais partes que respeitem a privacidade e a segurança de seus dados pessoais e que as tratem de acordo com a lei. Nós não permitimos que essas outras partes usem seus dados pessoais para seus próprios fins e somente permitimos que elas processem suas informações para fins específicos e de acordo com nossas instruções.</p>
                    <p>Em particular, suas informações podem ser divulgadas para as seguintes categorias de destinatários:</p>
                    <ul>
                        <li>Consultores profissionais (por exemplo, consultores de TI, jurídicos e financeiros) e auditores.</li>
                        <li>Provedores de TI e de sistemas; provedores de desenvolvimento de sites e de armazenamento de dados; empresas de análise de dados e da Web; desenvolvedores Web3 e provedores de carteiras digitais.</li>
                        <li>Provedores de plataformas de comércio eletrônico e de serviços que operam e gerenciam lojas on-line em nosso nome; provedores de serviços de processamento de cartões e de pagamentos on-line.</li>
                        <li>Agências de marketing digital, móvel e de mídias sociais; agências de implantação de correio e e-mail; agências de reserva de visitas; agências de fulfillment de clientes; organizadores de eventos e competições e fotógrafos.</li>
                        <li>Provedores de serviços de resolução de identidade e de integração de dados; anunciantes de retargeting e remarketing on-line e em mídias sociais.</li>
                        <li>Prestadores de serviços de logística, distribuição e entrega; fábricas de produtos personalizados.</li>
                        <li>Agências de prevenção de fraudes e de consulta de crédito; especialistas em litígios e recuperação para recuperar falta de pagamentos ou dívidas que nos sejam devidas.</li>
                        <li>Recepção e segurança física de instalações para visitas a destilarias.</li>
                        <li>Associados comerciais, parceiros de negócios e outras partes com as quais possamos estabelecer negociações sobre um acordo comercial, por exemplo, joint ventures.</li>
                        <li>Outras partes para ou com as quais possamos decidir vender, transferir ou fundir partes de nossos negócios ou de nossos ativos.</li>
                        <li>Agências de aplicação da lei; reguladores financeiros e outras autoridades normativas relevantes; órgãos governamentais; autoridades fiscais; tribunais e órgãos de resolução de reclamações/disputas, sob circunstâncias em que sejamos obrigados a divulgar dados pessoais por lei.</li>
                    </ul>

                    <h2>Seus direitos de privacidade</h2>
                    <p>A legislação de algumas jurisdições poderá conceder direitos adicionais a você em relação ao uso de dados pessoais. Para saber mais sobre direitos adicionais que possam ser aplicáveis a você como residente de uma dessas jurisdições, consulte os adendos de privacidade a seguir, que estão anexados a este Aviso de Privacidade abaixo.</p>

                    <h2>Como entrar em contato conosco</h2>
                    <p>Entre em contato conosco se tiver alguma dúvida sobre a maneira como utilizamos suas informações, se quiser discutir seus direitos legais, ou se tiver alguma reclamação ou feedback sobre o uso que fazemos de suas informações. Entre em contato conosco a qualquer momento, enviando um e-mail para <a href="mailto:privacy@beamsuntory.com">privacy@beamsuntory.com</a>.</p>



                    <h1>Adendo de Privacidade da Austrália</h1>
    
                    <h2>Introdução</h2>
                    <p>Este adendo da lei de privacidade australiana (o "Adendo da Austrália") complementa ou modifica as informações contidas no Aviso de Privacidade da Beam Suntory (o "Aviso de Privacidade"). Este Adendo da Austrália se aplica à coleta, uso, transferência e divulgação de informações pessoais pela Beam Suntory Australia Pty Ltd., Jim Beam Brands Co., Beam Suntory Inc. e suas subsidiárias, controladoras e empresas afiliadas.</p>
                    <p>Nós adotamos este Adendo da Austrália para cumprir a Lei de Privacidade de 1988 (Cth) e os Princípios de Privacidade Australianos (coletivamente, as "Leis de Privacidade Australianas").</p>

                    <h2>Informações Coletadas</h2>
                    <p>Podemos coletar informações confidenciais suas, por exemplo, suas preferências alimentares. Somente coletaremos informações confidenciais suas se isso for razoavelmente necessário nas circunstâncias em questão e se você tiver consentido com essa coleta, ou se tivermos o direito de fazer isso de acordo com as Leis de Privacidade Australianas.</p>

                    <h2>Medidas de Segurança</h2>
                    <p>Nós utilizamos medidas de segurança razoáveis para proteger seus dados pessoais, levando em conta o estado atual de conhecimento sobre o tema, os custos de implementação e a natureza, escopo, contexto e finalidades do processamento, bem como o risco de probabilidade e gravidade variáveis para os direitos e liberdades dos consumidores.</p>
                    <p>Recomendamos que você se mantenha atento a seus dados pessoais e tenha cautela.</p>

                    <h2>Seus Direitos</h2>
                    <p>Você pode entrar em contato conosco para solicitar acesso às informações pessoais que mantemos sobre você, inclusive para fins de correção dessas informações pessoais, usando a opção "Fazer uma solicitação".</p>

                    <h2>Transferências de Informações</h2>
                    <p>Como a Beam Suntory é uma empresa internacional, pode haver casos em que seja necessário transferir suas informações para fora da Austrália, por exemplo, para nossa sede nos Estados Unidos e para diferentes subsidiárias (localizadas no Espaço Econômico Europeu, em Singapura e em outros países).</p>

                    <h2>Informações de Contato</h2>
                    <p>Entre em contato se tiver dúvidas ou comentários sobre a maneira como coletamos, usamos ou gerenciamos suas informações pessoais. Você pode entrar em contato conosco pelo e-mail <a href="mailto:privacy@beamsuntory.com">privacy@beamsuntory.com</a>.</p>
                    <p>De acordo com as Leis de Privacidade Australianas, você tem o direito de fazer uma reclamação ao Office of the Australian Information Commissioner ("OAIC"). Mais detalhes sobre como protocolar uma reclamação no OAIC podem ser encontrados no site <a href="https://www.oaic.gov.au/privacy/privacy-complaints/lodge-a-privacy-complaint-with-us">https://www.oaic.gov.au/privacy/privacy-complaints/lodge-a-privacy-complaint-with-us</a>.</p>

                    <h1>Adendo de Privacidade do Brasil</h1>

                    <h2>Introdução</h2>
                    <p>Este Adendo de Privacidade do Brasil (o "Adendo do Brasil") complementa as informações contidas no Aviso de Privacidade da Beam Suntory (o "Aviso de Privacidade") e se aplica a você(s) ("consumidor(es)” ou "você(s)") somente nas seguintes circunstâncias:</p>
                    <ul>
                        <li>Se a atividade de tratamento de dados pessoais for realizada no Brasil.</li>
                        <li>Se a finalidade da atividade de tratamento de dados pessoais for oferecer ou fornecer bens ou serviços, ou processar dados de indivíduos localizados no território nacional brasileiro.</li>
                        <li>Se os dados pessoais tiverem sido coletados no Brasil.</li>
                    </ul>
                    <p>Nós adotamos este Adendo do Brasil para cumprir a Lei Geral de Proteção de Dados Pessoais (Lei nº 13.709/2018; "LGPD").</p>

                    <h2>Informações Coletadas</h2>
                    <p>Coletamos somente dados pessoais limitados ao mínimo de informações necessárias para cumprir a finalidade estabelecida pela empresa para a atividade de tratamento específica, caso a caso e em conformidade com a LGPD.</p>
                    <p>Para mais informações sobre dados técnicos que coletamos, acesse nossa Política de Cookies.</p>

                    <h2>Seus Direitos</h2>
                    <p>De acordo com a LGPD, você pode ter os seguintes direitos:</p>
                    <ul>
                        <li>Confirmação da existência de tratamento;</li>
                        <li>Acesso aos dados;</li>
                        <li>Correção de dados incompletos, inexatos ou desatualizados;</li>
                        <li>Anonimização, bloqueio ou exclusão de dados desnecessários;</li>
                        <li>Portabilidade dos dados a outro fornecedor de serviço ou produto;</li>
                        <li>Eliminação dos dados pessoais tratados com o consentimento do titular;</li>
                        <li>Informações sobre o uso compartilhado de dados;</li>
                        <li>Informações sobre a possibilidade de não fornecer o consentimento;</li>
                        <li>Solicitação de reconsideração de decisões automatizadas;</li>
                        <li>Direito de acesso facilitado às informações sobre o tratamento de dados.</li>
                    </ul>

                    <h2>Transferências de Informações</h2>
                    <p>Como a Beam Suntory é uma empresa internacional, pode haver casos em que seja necessário transferir suas informações para fora do Brasil, por exemplo, para a União Europeia ou para os Estados Unidos.</p>

                    <h2>Informações de Contato</h2>
                    <p>A Beam Suntory Brasil Importação e Comércio de Bebidas Ltda. é a controladora dos dados pessoais que mantemos sobre você. Em caso de dúvidas ou comentários, não hesite em entrar em contato com nosso Encarregado pelo e-mail <a href="mailto:encarregado.beamsuntorybrasil@toisa.com.br">encarregado.beamsuntorybrasil@toisa.com.br</a>.</p>




                                    
                    <h1>Adendo de Privacidade da União Europeia/Reino Unido</h1>

                    <h2>Introdução</h2>
                    <p>Este Adendo de Privacidade da União Europeia/Reino Unido (o "Adendo da UE/Reino Unido") complementa as informações contidas no Aviso de Privacidade da Beam Suntory (o "Aviso de Privacidade") e se aplica a você(s) ("consumidor(es)" ou "você(s)") somente nas seguintes circunstâncias:</p>
                    <ul>
                        <li>Você está na União Europeia ou no Reino Unido; ou,</li>
                        <li>Você está acessando o site de uma subsidiária da Beam Suntory Inc. que opera na União Europeia ou no Reino Unido, a despeito de onde você esteja localizado no mundo.</li>
                    </ul>
                    <p>Nós adotamos o Adendo da UE/Reino Unido para cumprir com (1) o Regulamento Geral sobre a Proteção de Dados (UE) 2016/679 ("RGPD"), (2) o Regulamento Geral de Proteção de Dados do Reino Unido ("RGPD do Reino Unido") e (3) a Lei de Proteção de Dados de 2018.</p>

                    <h2>Seus direitos</h2>
                    <p>De acordo com o RGPD ou com a legislação local equivalente, você pode ter os seguintes direitos:</p>
                    <ul>
                        <li><strong>Direito de acesso do titular:</strong> Você pode solicitar que forneçamos a você uma cópia dos dados pessoais que mantemos sobre você.</li>
                        <li><strong>Direito de correção:</strong> Se você acredita que alguma informação que mantemos sobre você pode estar inexata, você pode nos solicitar para corrigir tais inexatidões.</li>
                        <li><strong>Direito de eliminação:</strong> Você pode nos pedir para eliminar suas informações em determinadas circunstâncias.</li>
                        <li><strong>Direito à portabilidade de dados:</strong> Você pode solicitar que suas informações sejam fornecidas a outra parte em um formato legível por máquina.</li>
                        <li><strong>Direito à restrição de processamento:</strong> Você pode nos pedir a restrição ou suspensão do processamento de suas informações em determinadas circunstâncias.</li>
                        <li><strong>Direito de se opor ao processamento:</strong> Você tem o direito de se opor ao processamento de suas informações em determinadas circunstâncias.</li>
                        <li><strong>Direito de revogar o consentimento:</strong> Você pode revogar seu consentimento a qualquer momento.</li>
                        <li><strong>Direito de protocolar uma reclamação:</strong> Você pode fazer uma reclamação a uma autoridade supervisora.</li>
                    </ul>

                    <h2>Transferências de informações</h2>
                    <p>Como a Beam Suntory é uma empresa internacional, pode haver casos em que seja necessário transferir suas informações para fora da União Europeia ou do Reino Unido, implementando proteções adequadas.</p>

                    <h2>Informações de contato</h2>
                    <p>A Beam Suntory Inc. é a controladora dos dados pessoais que mantemos sobre você. Em caso de dúvidas, entre em contato pelo e-mail <a href="mailto:privacy@beamsuntory.com">privacy@beamsuntory.com</a>.</p>

                    <h1>Adendo de Privacidade da China Continental</h1>

                    <h2>Introdução</h2>
                    <p>Este Adendo de Privacidade da China Continental (o "Adendo da China") complementa as informações contidas no Aviso de Privacidade da Beam Suntory.</p>

                    <h2>Seus direitos</h2>
                    <p>De acordo com a PIPL, você pode ter os seguintes direitos:</p>
                    <ul>
                        <li><strong>Direito de acesso do titular:</strong> Você pode solicitar que forneçamos uma cópia dos dados pessoais que mantemos sobre você.</li>
                        <li><strong>Direito de correção:</strong> Você pode nos solicitar para corrigir informações inexatas.</li>
                        <li><strong>Direito de eliminação:</strong> Você pode nos pedir para eliminar suas informações em determinadas circunstâncias.</li>
                        <li><strong>Direito à portabilidade de dados:</strong> Você pode solicitar a transferência de informações pessoais.</li>
                        <li><strong>Direito de revogar o consentimento:</strong> Você pode revogar seu consentimento a qualquer momento.</li>
                    </ul>

                    <h2>Informações de contato</h2>
                    <p>A Beam Suntory Inc. é a processadora das informações pessoais. Se você estiver localizado na China, entre em contato com <a href="mailto:privacy@beamsuntory.com">privacy@beamsuntory.com</a>.</p>


 

                    <h1>Adendo de Privacidade da Nova Zelândia</h1>
                    <p><strong>Introdução:</strong> Este adendo da lei de privacidade da Nova Zelândia (o "Adendo da Nova Zelândia") complementa ou modifica as informações contidas no Aviso de Privacidade da Beam Suntory (o "Aviso de Privacidade"). Este Adendo da Nova Zelândia se aplica à coleta, uso, armazenamento, transferência e divulgação de dados pessoais pela Beam Suntory NZ Limited, suas controladoras e empresas afiliadas.</p>
                    <p>Este Adendo da Nova Zelândia aplica-se, em adição a e sem se limitar a, nossos direitos e obrigações nos termos da Lei de Privacidade de 2020 (conforme alterada ou substituída de tempos em tempos) ("Lei de Privacidade da Nova Zelândia"), ou de qualquer autorização específica que você nos fornecer quando interagir conosco ou utilizar nossos serviços.</p>
                    
                    <h3>Informações coletadas</h3>
                    <p>Você não é obrigado a nos fornecer nenhuma das informações pessoais que solicitarmos. No entanto, se você não fizer isso, talvez não possamos fornecer determinados serviços a você.</p>

                    <h3>Seus direitos</h3>
                    <p>De acordo com a Lei de Privacidade da Nova Zelândia, você tem o direito de acessar e solicitar a correção de qualquer dado pessoal que mantemos sobre você, usando a opção "Fazer uma solicitação". Contamos com você para nos atualizar, caso seus dados de contato sejam alterados.</p>

                    <h3>Informações de contato</h3>
                    <p class="contact">Entre em contato se tiver dúvidas ou comentários sobre a maneira como coletamos, usamos ou gerenciamos suas informações pessoais. Você pode entrar em contato conosco pelo e-mail <a href="mailto:privacy@beamsuntory.com">privacy@beamsuntory.com</a>.</p>

                    <h2>Adendo de Privacidade de Singapura</h2>
                    <p><strong>Introdução:</strong> Este Adendo de Privacidade de Singapura ("Adendo de Singapura") complementa, detalha ou modifica as informações contidas no Aviso de Privacidade da Beam Suntory e se aplica a todos os visitantes e usuários do nosso site originários de Singapura ("você(s)"). Nós adotamos o Adendo de Singapura para cumprir a Lei de Proteção de Dados Pessoais de 2012 ("PDPA").</p>
                    <p>Este Adendo de Singapura foi elaborado para ajudá-lo a entender como podemos coletar, usar, divulgar ou processar os dados pessoais que você nos fornece de acordo com a PDPA e a tomar decisões conscientes quando estiver usando nosso site.</p>
                    
                    <h3>Informações coletadas</h3>
                    <p>Em geral, nós confiramos nos dados pessoais fornecidos por você (ou seu representante autorizado). Para garantir que os seus dados pessoais são atuais, completos e exatos, atualize nos se houver alterações nos seus dados pessoais.</p>

                    <h3>Seus direitos</h3>
                    <p>O consentimento que você fornecer para a coleta, uso e divulgação de seus dados pessoais permanecerá válido até que seja revogado por você por escrito. Você pode revogar o consentimento e solicitar que interrompamos a coleta, uso e/ou divulgação de seus dados pessoais para qualquer ou todos os fins listados acima, usando a opção "Fazer uma solicitação".</p>

                    <h3>Informações de contato</h3>
                    <p class="contact">Em caso de dúvidas ou perguntas sobre este Adendo de Singapura, nossas políticas e procedimentos de proteção de dados pessoais, não hesite em entrar em contato com nosso Diretor de Proteção de Dados de Singapura pelo e-mail <a href="mailto:privacy@beamsuntory.com">privacy@beamsuntory.com</a>.</p>

                    <h2>Adendo de Privacidade da África do Sul</h2>
                    <p><strong>Introdução:</strong> Este Adendo da África do Sul (o "Adendo da África do Sul") complementa as informações contidas no Aviso de Privacidade da Beam Suntory (o "Aviso de Privacidade") e se aplica às informações pessoais coletadas, compartilhadas e utilizadas por qualquer subsidiária ou afiliada corporativa da Beam Suntory que esteja constituída na África do Sul ou que processe informações pessoais na África do Sul. Nós adotamos este Adendo da África do Sul para cumprir a Lei de Proteção de Informações Pessoais 4 de 2013 (POPIA).</p>

                    <h3>Seus direitos</h3>
                    <p>De acordo com a POPIA, os titulares dos dados têm os seguintes direitos:</p>
                    <ul>
                        <li>Direito de ser notificado que as informações pessoais a seu respeito estão sendo coletadas e que suas informações pessoais foram acessadas ilegalmente.</li>
                        <li>Direito de acesso para confirmar se um controlador de dados mantém informações pessoais a seu respeito e para solicitar que o controlador dos dados corrija, destrua ou elimine as informações pessoais a seu respeito.</li>
                        <li>Direito de se opor por motivos razoáveis, ao processamento de suas informações pessoais e de se opor, a qualquer momento, ao processamento de suas informações pessoais para fins de marketing direto.</li>
                        <li>Direito de rejeitar comunicações eletrônicas não solicitadas para fins de marketing direto.</li>
                        <li>Direito de apresentar uma reclamação ao órgão regulador de informações em caso de suposta interferência em suas informações pessoais.</li>
                        <li>Direito de instaurar processos civis por danos, no caso de qualquer suposta interferência em suas informações pessoais.</li>
                    </ul>

                    <h3>Informações de contato</h3>
                    <p class="contact">Em caso de dúvidas ou comentários sobre este Adendo da África do Sul, sobre as maneiras utilizadas pela Beam Suntory para coletar e utilizar suas informações, não hesite em entrar em contato pelo e-mail <a href="mailto:privacy@beamsuntory.com">privacy@beamsuntory.com</a>.</p>


                    <h1>Adendo de Privacidade dos Estados Unidos</h1>
                    <p>Este Adendo de Privacidade dos Estados Unidos (o "Adendo dos EUA") complementa as informações contidas no Aviso de Privacidade da Beam Suntory (o "Aviso de Privacidade") e aplica-se exclusivamente a todos os visitantes, usuários e outras pessoas que tenham direitos de acordo com a legislação estadual abrangente sobre privacidade ("consumidor(es)" ou "você(s)"). A menos que definido de outra forma no Aviso de Privacidade, os termos definidos neste Adendo dos EUA têm o significado usado na legislação de privacidade estadual abrangente aplicável. Observe que este Adendo dos EUA não se aplica a nossos empregados efetivos ou potenciais. Se você for um empregado e quiser obter mais informações sobre como tratamos suas dados pessoais, entre em contato com o departamento de recursos humanos local.</p>

                    <h2>Informações pessoais sigilosas</h2>
                    <p>De acordo com as leis de privacidade dos Estados Unidos, determinados tipos de informações pessoais são considerados informações ou dados pessoais "sigilosas" e exigem direitos e obrigações adicionais de privacidade de dados. A Beam Suntory coleta Dados financeiros e Dados de login, conforme descrito na seção "As informações que coletamos sobre você" no Aviso de Privacidade, que podem ser consideradas informações pessoais sigilosas de acordo com a Lei de privacidade da Califórnia (California Privacy Rights Act, ou "CPRA"). Nós coletamos essas informações pessoais sigilosas apenas para fornecer os bens ou serviços que você solicitou, detectar incidentes de segurança e resistir a atividades maliciosas, enganosas, fraudulentas ou ilegais ("Usos permitidos"). Como a Beam Suntory usa essas informações pessoais sigilosas para Usos permitidos, não precisamos oferecer um direito de Limite de uso e divulgação de informações pessoais sigilosas nos termos da CPRA. A Beam Suntory também não coleta nenhuma informação que possa ser considerada como dado pessoal sigiloso nos termos das leis de privacidade de outros estados, o que exigiria a obtenção do consentimento seu.</p>

                    <h2>Venda ou compartilhamento de informações pessoais</h2>
                    <p>Nós podemos "vender" ou "compartilhar" suas informações pessoais com terceiros, ou seja, agências de marketing digital, móvel e de mídias sociais, redes de publicidade e/ou provedores de conteúdo e outras entidades que nos forneçam serviços de análise, publicidade e marketing, inclusive em conexão com os programas NFT, Web3 e de carteira digital (coletivamente, "Provedores de marketing"). Nós "vendemos" ou "compartilhamos" suas informações pessoais com Provedores de marketing para fins comerciais ou empresariais de análise, marketing e publicidade direcionada. Consulte a nossa Política de Cookies para obter mais detalhes. A Beam Suntory não tem conhecimento real de nenhuma coleta, uso, venda ou compartilhamento de informações pessoais de consumidores com menos de 16 anos de idade. Para obter mais detalhes sobre as categorias de informações pessoais que podemos vender ou compartilhar atualmente e nos últimos 12 meses, veja abaixo.</p>

                    <h2>Resumo das práticas de tratamento de informações pessoais</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Categorias de informações pessoais</th>
                                <th>Fontes</th>
                                <th>Finalidade de negócios ou comercial do processamento, divulgação e destinatários das informações pessoais</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Identificadores, tais como o nome real, endereço postal, endereço de protocolo da Internet, endereço de e-mail, nome da conta, identificadores pessoais exclusivos ou outros identificadores semelhantes</td>
                                <td>Você ou outras partes</td>
                                <td>
                                    <strong>Vendidas ou compartilhadas:</strong> Podemos ter vendido ou compartilhado esta categoria de informações pessoais com provedores de marketing para fins de análise, marketing e publicidade direcionada.<br />
                                    <strong>Provedores de serviços:</strong> Podemos ter divulgado esta categoria de informações pessoais para nossas subsidiárias e a outras partes que nos prestam serviços ou agem em nosso nome em associação à operação de nossos negócios.
                                </td>
                            </tr>
                            <tr>
                                <td>Qualquer informação pessoal descrita Código Civil da Califórnia § 1798.80(e), tal como nome, endereço, número de telefone e informações financeiras</td>
                                <td>Você ou outras partes</td>
                                <td>
                                    <strong>Vendidas ou compartilhadas:</strong> Podemos ter vendido ou compartilhado esta categoria de informações pessoais com provedores de marketing para fins de análise, marketing e publicidade direcionada.<br />
                                    <strong>Provedores de serviços:</strong> Podemos ter divulgado esta categoria de informações pessoais para nossas subsidiárias e a outras partes que nos prestam serviços ou agem em nosso nome em associação à operação de nossos negócios.
                                </td>
                            </tr>
                            <tr>
                                <td>Características de classificações protegidas pela legislação federal ou da Califórnia, tais como idade</td>
                                <td>Você ou outras partes</td>
                                <td>
                                    <strong>Vendidas ou compartilhadas:</strong> Não vendemos ou compartilhamos esta categoria de informações pessoais.<br />
                                    <strong>Provedores de serviços:</strong> Podemos ter divulgado esta categoria de informações pessoais para nossas subsidiárias e a outras partes que nos prestam serviços ou agem em nosso nome em associação à operação de nossos negócios.
                                </td>
                            </tr>
                            <tr>
                                <td>Informações comerciais, tais como produtos ou serviços adquiridos</td>
                                <td>Você ou outras partes</td>
                                <td>
                                    <strong>Vendidas ou compartilhadas:</strong> Podemos ter vendido ou compartilhado esta categoria de informações pessoais com provedores de marketing para fins de análise, marketing e publicidade direcionada.<br />
                                    <strong>Provedores de serviços:</strong> Podemos ter divulgado esta categoria de informações pessoais para nossas subsidiárias e a outras partes que nos prestam serviços ou agem em nosso nome em associação à operação de nossos negócios.
                                </td>
                            </tr>
                            <tr>
                                <td>Informações sobre atividades na Internet ou em outras redes eletrônicas</td>
                                <td>Você ou outras partes</td>
                                <td>
                                    <strong>Vendidas ou compartilhadas:</strong> Podemos ter vendido ou compartilhado esta categoria de informações pessoais com provedores de marketing para fins de análise, marketing e publicidade direcionada.<br />
                                    <strong>Provedores de serviços:</strong> Podemos ter divulgado esta categoria de informações pessoais para nossas subsidiárias e a outras partes que nos prestam serviços ou agem em nosso nome em associação à operação de nossos negócios.
                                </td>
                            </tr>
                            <tr>
                                <td>Dados de geolocalização</td>
                                <td>Você ou outras partes</td>
                                <td>
                                    <strong>Vendidas ou compartilhadas:</strong> Podemos ter vendido ou compartilhado esta categoria de informações pessoais com provedores de marketing para fins de análise, marketing e publicidade direcionada.<br />
                                    <strong>Provedores de serviços:</strong> Podemos ter divulgado esta categoria de informações pessoais para nossas subsidiárias e a outras partes que nos prestam serviços ou agem em nosso nome em associação à operação de nossos negócios.
                                </td>
                            </tr>
                            <tr>
                                <td>Informações ou dados pessoais sigilosos, tais como credenciais e senhas de usuários dos consumidores e detalhes de contas bancárias e cartões de pagamento</td>
                                <td>Você ou outras partes</td>
                                <td>
                                    <strong>Vendidas ou compartilhadas:</strong> Não vendemos ou compartilhamos esta categoria de informações pessoais.<br />
                                    <strong>Provedores de serviços:</strong> Podemos ter divulgado esta categoria de informações pessoais para nossas subsidiárias e a outras partes que nos prestam serviços ou agem em nosso nome em associação à operação de nossos negócios.
                                </td>
                            </tr>
                            <tr>
                                <td>Inferências, com base nas informações pessoais acima, para criar um perfil sobre você que reflita suas preferências, características, predisposições e comportamentos</td>
                                <td>Você ou outras partes</td>
                                <td>
                                    <strong>Vendidas ou compartilhadas:</strong> Podemos ter vendido ou compartilhado esta categoria de informações pessoais com provedores de marketing para fins de análise, marketing e publicidade direcionada.<br />
                                    <strong>Provedores de serviços:</strong> Podemos ter divulgado esta categoria de informações pessoais para nossas subsidiárias e a outras partes que nos prestam serviços ou agem em nosso nome em associação à operação de nossos negócios.
                                </td>
                            </tr>
                            <tr>
                                <td>Informações profissionais ou relacionadas a empregos</td>
                                <td>Você ou outras partes</td>
                                <td>
                                    <strong>Vendidas ou compartilhadas:</strong> Não vendemos ou compartilhamos esta categoria de informações pessoais.<br />
                                    <strong>Provedores de serviços:</strong> Podemos ter divulgado esta categoria de informações pessoais para nossas subsidiárias e a outras partes que nos prestam serviços ou agem em nosso nome em associação à operação de nossos negócios.
                                </td>
                            </tr>
                            <tr>
                                <td>Informações de áudio, eletrônicas, visuais ou similares</td>
                                <td>Você ou outras partes</td>
                                <td>
                                    <strong>Vendidas ou compartilhadas:</strong> Não vendemos ou compartilhamos esta categoria de informações pessoais.<br />
                                    <strong>Provedores de serviços:</strong> Podemos ter divulgado esta categoria de informações pessoais para nossas subsidiárias e a outras partes que nos prestam serviços ou agem em nosso nome em associação à operação de nossos negócios.
                                </td>
                            </tr>
                        </tbody>
                    </table>


                    

                    <h2>Terceiros que controlam a coleta de informações pessoais</h2>
                    <p>Nós permitimos que nossos Provedores de marketing controlem a coleta de informações pessoais em nosso site e durante a prestação de nossos serviços. Consulte a nossa <a href="link-para-politica-de-cookies.html">Política de Cookies</a> para obter mais detalhes.</p>

                    <h2>Retenção de informações pessoais</h2>
                    <p>Nós armazenamos informações pessoais pelo tempo necessário para executar as finalidades para as quais as coletamos originalmente e para outras finalidades comerciais legítimas, inclusive para cumprir nossas obrigações legais, regulatórias ou outras obrigações de conformidade. Para obter mais detalhes sobre nossa política de retenção de informações pessoais, entre em contato conosco pelo e-mail <a href="mailto:privacy@beamsuntory.com">privacy@beamsuntory.com</a> ou pelo telefone +1 855 380 9577.</p>

                    <h2>Seus direitos e opções</h2>
                    <p>As leis de privacidade aplicáveis concedem aos consumidores residentes nos Estados Unidos determinados direitos em relação às suas informações pessoais, sujeitos a certas exceções. Condicionado a certas limitações, você tem os seguintes direitos nos Estados Unidos:</p>
                    <ul>
                        <li><strong>Direito de eliminação:</strong> Você tem o direito de solicitar a eliminação das informações pessoais que coletamos sobre você.</li>
                        <li><strong>Direito de correção:</strong> Você tem o direito de nos solicitar a correção de informações pessoais inexatas que mantemos sobre você.</li>
                        <li><strong>Confirmação de processamento:</strong> Você tem o direito de confirmar se estamos processando suas informações pessoais.</li>
                        <li><strong>Direito de saber e acesso:</strong> Você tem o direito de saber e acessar as informações pessoais que coletamos sobre você, incluindo as categorias de informações pessoais e suas fontes.</li>
                        <li><strong>Portabilidade de dados:</strong> Você tem o direito de receber as informações em um formato que seja portátil e utilizável.</li>
                        <li><strong>Lista de terceiros específicos:</strong> Você tem o direito de receber uma lista de terceiros específicos para os quais divulgamos suas informações pessoais.</li>
                        <li><strong>Direito de oposição:</strong> Você tem o direito de se opor a determinados processamentos, como a venda ou o compartilhamento de suas informações pessoais.</li>
                        <li><strong>Direito à não discriminação:</strong> Você tem o direito de não ser discriminado por exercer qualquer um de seus direitos de privacidade.</li>
                        <li><strong>Direito de recurso:</strong> Se nos recusarmos a tomar providências em resposta ao seu exercício de um direito de privacidade, informaremos a justificativa e forneceremos instruções sobre como recorrer da decisão.</li>
                    </ul>

                    <h2>Exercício de seus direitos</h2>
                    <p>Para exercer seus direitos descritos acima, envie-nos uma solicitação verificável do consumidor:</p>
                    <ul>
                        <li>Entre em contato conosco pelo telefone +1 855 380 9577.</li>
                        <li>Acesse <a href="link-para-fazer-solicitacao.html">Fazer uma solicitação</a>.</li>
                    </ul>

                    <h2>Recurso de uma negação a uma solicitação de direitos</h2>
                    <p>Você poderá recorrer de uma negação às suas solicitações de direitos de privacidade:</p>
                    <ul>
                        <li>Entre em contato conosco pelo telefone +1 855 380 9577.</li>
                        <li>Acesse <a href="link-para-fazer-solicitacao.html">Fazer uma solicitação</a>.</li>
                    </ul>

                    <h2>Agentes autorizados</h2>
                    <p>Se permitido ou exigido por lei, você poderá exercer seus direitos de privacidade por meio de um agente autorizado. Se recebermos sua solicitação de um agente autorizado, poderemos solicitar evidências de autorização.</p>

                    <h2>Divulgação adicional para residentes da Califórnia</h2>
                    <p>A legislação da Califórnia permite que os residentes da Califórnia solicitem detalhes sobre como suas informações são compartilhadas com terceiros para fins de marketing direto. Se você reside na Califórnia e gostaria de fazer tal solicitação, entre em contato conosco pelo e-mail <a href="mailto:privacy@beamsuntory.com">privacy@beamsuntory.com</a> ou pelo telefone +1 855 380 9577.</p>

                    <h2>Informações de contato</h2>
                    <p>Em caso de dúvidas ou comentários sobre este Adendo dos EUA, entre em contato conosco pelo e-mail <a href="mailto:privacy@beamsuntory.com">privacy@beamsuntory.com</a> ou pelo telefone +1 855 380 9577.</p>










                    {/*                     
                    <IframeTerms />
                    <TitleContent>
                        <Content>
                            <Title noSpace>Termos de uso</Title>
                            <p><strong>Última atualização:</strong> 14/09/2024</p>
                        </Content>
                    </TitleContent>


                    <p>Bem-vindo ao Prime Spirits Club! Ao acessar e utilizar nosso site, você concorda com os seguintes Termos de Uso. Caso não concorde com estes termos, por favor, não utilize o site.</p>

                    <h2>1. Aceitação dos Termos</h2>
                    <p>1.1. Ao acessar e usar o Prime Spirits Club (o “Site”), você concorda em cumprir e se sujeitar a estes Termos de Uso e nossa Política de Privacidade.</p>
                    <p>1.2. Reservamo-nos o direito de alterar estes Termos de Uso a qualquer momento. As alterações serão publicadas nesta página e entrarão em vigor imediatamente após a publicação. Seu uso contínuo do Site após tais modificações constitui sua aceitação das novas condições.</p>

                    <h2>2. Uso do Site</h2>
                    <p>2.1. O Site é destinado exclusivamente para a venda de bebidas importadas e produtos relacionados. Você concorda em utilizar o Site apenas para fins legais e de acordo com estes Termos de Uso.</p>
                    <p>2.2. É proibido usar o Site para qualquer atividade que seja ilegal, prejudicial ou que possa danificar a reputação do Prime Spirits Club.</p>

                    <h2>3. Conta e Segurança</h2>
                    <p>3.1. Para acessar certas áreas do Site, pode ser necessário criar uma conta. Você concorda em fornecer informações precisas e completas durante o processo de registro e em manter essas informações atualizadas.</p>
                    <p>3.2. Você é responsável por manter a confidencialidade de sua conta e senha e por todas as atividades que ocorram sob sua conta. Notifique-nos imediatamente sobre qualquer uso não autorizado de sua conta.</p>

                    <h2>4. Compra de Produtos</h2>
                    <p>4.1. Todos os preços e descrições de produtos estão sujeitos a alteração sem aviso prévio. O Prime Spirits Club reserva-se o direito de cancelar qualquer pedido se houver erros na descrição do produto ou no preço.</p>
                    <p>4.2. Ao fazer um pedido, você concorda em fornecer informações precisas sobre o endereço de entrega e outras informações necessárias para a conclusão da transação.</p>
                    <p>4.3. O Prime Spirits Club não se responsabiliza por atrasos na entrega causados por terceiros, incluindo transportadoras e serviços de correios.</p>

                    <h2>5. Propriedade Intelectual</h2>
                    <p>5.1. Todo o conteúdo disponível no Site, incluindo textos, gráficos, logotipos, imagens e software, é propriedade do Prime Spirits Club ou de seus licenciadores e está protegido por leis de propriedade intelectual.</p>
                    <p>5.2. É proibido reproduzir, distribuir, modificar ou criar trabalhos derivados do conteúdo do Site sem a permissão expressa por escrito do Prime Spirits Club.</p>

                    <h2>6. Isenção de Responsabilidade</h2>
                    <p>6.1. O Site e seu conteúdo são fornecidos “como estão” e “conforme disponíveis”. O Prime Spirits Club não faz garantias de qualquer tipo, expressas ou implícitas, sobre a precisão, integridade ou adequação das informações e produtos disponíveis no Site.</p>
                    <p>6.2. O Prime Spirits Club não será responsável por quaisquer danos diretos, indiretos, incidentais, especiais ou consequenciais que possam resultar do uso ou da incapacidade de uso do Site.</p>

                    <h2>7. Links para Sites de Terceiros</h2>
                    <p>7.1. O Site pode conter links para sites de terceiros. Esses links são fornecidos apenas para sua conveniência e não implicam endosse ou responsabilidade pelo conteúdo desses sites.</p>

                    <h2>8. Lei Aplicável e Jurisdição</h2>
                    <p>8.1. Estes Termos de Uso serão regidos e interpretados de acordo com as leis do Brasil, sem considerar seus conflitos de princípios legais.</p>
                    <p>8.2. Qualquer disputa decorrente ou relacionada a estes Termos de Uso será resolvida nos tribunais competentes da cidade São Paulo, e você consente com a jurisdição pessoal desses tribunais.</p>

                    <h2>9. Contato</h2>
                    <p>9.1. Se você tiver qualquer dúvida sobre estes Termos de Uso, entre em contato conosco através do e-mail <a href={`mailto:${ contactEmail }`}>{ contactEmail}</a> .</p>

                    <p>Por favor, revise este documento periodicamente para se manter atualizado com qualquer alteração.</p> */}






                </FormContent>
                {/* <Brands /> */}
                <PaymentMethods />
                <Footer />
            </ContainerAuthenticated>
        </>
    );
}