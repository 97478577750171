import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormSpace, Icon, Overlay, SideContent, SideOptions, SideOptionsContent, SideTitle } from './styled';
import { sidebarOptions } from 'utils/options';
import Check from 'components/Form/Check';
import Scroll from 'components/Scroll';
import useWindowSize from 'utils/useWindowSize';
import { CoreContext } from 'context/CoreContext';
import { useLocation } from 'react-router-dom';

export default function SideBar({ isResponsiveActive, handleResponsive }) {
  const [active, setActive] = useState([]);
  const windowSize = useWindowSize();
  const { categories, cat, setCat, sup, setSup, departments, dep, setDep } = useContext(CoreContext);
  const location = useLocation();

  const sideOp = useMemo(() => {
    return sidebarOptions?.map(m => (m?.id === 1 ? { ...m, options: categories } : m));
  }, [categories]);

  const sideDep = useMemo(() => {
    return [...departments].map(m => ({ ...m, type: 'departments' }));
  }, [departments]);


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);


    const categoryIds = queryParams.get('category');
    if (categoryIds) {
      const categoryArray = categoryIds.split(',').map(id => parseInt(id, 10));
      setCat(categoryArray);
    }


    const departmentId = queryParams.get('departments');
    const optionId = queryParams.get('option');
    if (departmentId) {
      if (departmentId && optionId) {
        const department = departments.find(dep => dep.id === parseInt(departmentId, 10));
        if (department) {
          const option = department.options.find(opt => opt.id === parseInt(optionId, 10));
          setDep(prevDep => [
            { option: department, subOption: option },
          ]);
        }
      }
    }
  }, [location.search, setCat, setDep, departments]);

  const handleQuestionClick = (id) => {
    if (active.includes(id)) {
      setActive(active.filter(optionId => optionId !== id));
    } else {
      setActive([...active, id]);
    }
  };

  const handleCheckChange = (option, subOption, checked) => {
    console.log('option', option);
    console.log('subOption', subOption);

    if (option?.type === 'categories') {
      setCat(cat?.includes(subOption?.id) ? cat?.filter(f => f !== subOption?.id) : [...cat, subOption?.id]);
    }

    if (option?.type === 'suppliers') {
      setSup(sup?.includes(subOption?.id) ? sup?.filter(f => f !== subOption?.id) : [...sup, subOption?.id]);
    }

    if (option?.type === 'departments') {
      setDep(dep?.map(m => m?.subOption?.id)?.includes(subOption?.id)
        ? dep?.filter(f => f?.subOption?.id !== subOption?.id)
        : [...dep, { option, subOption }]);
    }

    handleResponsive();
  };

  const isCheked = (option, subOption) => {
    if (option?.type === 'categories') {
      return cat?.includes(subOption?.id);
    }

    if (option?.type === 'suppliers') {
      return sup?.includes(subOption?.id);
    }
    if (option?.type === 'departments') {
      return dep?.map(m => m?.subOption?.id)?.includes(subOption?.id);
    }
    return false;
  };

  return (
    <>
      <Overlay active={isResponsiveActive} onClick={() => handleResponsive()} />

      <SideContent>
        {windowSize.width < 600 && <Icon onClick={handleResponsive} src={'/icons/close.svg'} />}
        {[...sideOp, ...sideDep].map((option, index) => (
          <SideOptionsContent key={index} top={index === 0}>
            <SideTitle onClick={() => handleQuestionClick(option.id)}>
              {option.title}
            </SideTitle>
            <Scroll maxHeight="240px">
              {option.options.map((subOption, subIndex) => (
                <SideOptions key={subIndex}>
                  <Check
                    label={subOption.title}
                    checked={isCheked(option, subOption)}
                    onChange={() => handleCheckChange(option, subOption)}
                    small
                  />
                </SideOptions>
              ))}
            </Scroll>
            <FormSpace />
          </SideOptionsContent>
        ))}
      </SideContent>
    </>
  );
}