import React from 'react'
import { DiscountContent, DiscountedPrice, OriginalPrice, PaymentConditions, PaymentDetails, Price, PriceContent } from './styled'
import { Icon } from 'ui/styled'
import { parseCurrency } from 'utils/parsers'

export default function ProductPrice({ originalPrice, discount, discountedPrice, paymentDetails, paymentConditions, navigate, id }) {
  return (
    <>
      <PriceContent>
        {
          !discount ? null :
          <DiscountContent>
            {originalPrice ? <OriginalPrice>{parseCurrency(originalPrice)}</OriginalPrice> : null}
          </DiscountContent>
        }
        <Price>
          <DiscountedPrice> {`${parseCurrency(discountedPrice)}`}</DiscountedPrice>
          <PaymentDetails>
            {paymentDetails}
          </PaymentDetails>
        </Price>
        <PaymentConditions>{paymentConditions}</PaymentConditions>
      </PriceContent>
    </>
  )
}
