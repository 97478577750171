import React from 'react'
import { BannerContent, BannerImage, BannerInfo, BannerProduct, BannerTitle, Content } from './styled'
import { parseStrapiImage } from 'utils'

export default function Banner({  product }) { 

  const baner = {
    title: 'ENVELHECIDO POR MAIS TEMPO PARA UM SABOR MAIS SUAVE.',
    image: parseStrapiImage(product?.banner?.[0]?.url),
    product: parseStrapiImage(product?.banner?.[0]?.url),
  }
  return (
    <>
      <BannerContent>
        <BannerImage url={baner.product}>
          {/* <Content>
            <BannerTitle>{baner.title}</BannerTitle>
            <BannerProduct src={baner.product} />
          </Content> */}
          <BannerProduct src={baner.product} />
        </BannerImage>
      </BannerContent>
    </>
  )
}
