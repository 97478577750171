import React from 'react'
import { FormBorder, HistoryContainer, HistoryImg, HistoryText, YearsText } from './styled'

export default function HistoryCard({ history }) {
  return (
    <>
      <HistoryContainer>
        <HistoryImg src={history?.image} />
        <YearsText>{history?.years}</YearsText>
        <FormBorder />
        <HistoryText bold>{history?.title}</HistoryText>
        <HistoryText>{history?.description}</HistoryText>
      </HistoryContainer>
    </>
  )
}
