import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import { MaterialCheckbox, StyledFormControlLabel } from './styled';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import { ThemedComponent } from "ui/theme";

export const Check = ({ checked, onChange, label, title, secondary, small, alignTop, success }) => {
    const [active, setActive] = useState(checked);

    const action = () => {
        const nv = !active;
        if (onChange && typeof onChange === 'function') { onChange(nv); }
        setActive(nv);
    };

    useEffect(() => {
        setActive(checked);
    }, [checked]);

    return (
        <ThemedComponent>
            <FormGroup>
                {title ? <FormLabel component="legend" color={secondary ? 'secondary' : 'primary'} style={{textTransform:'capitalize'}}>{title}</FormLabel> : null}
                <StyledFormControlLabel
                    control={
                        <MaterialCheckbox
                            checked={active}
                            onChange={action}
                            small={small}
                            color={secondary ? 'secondary' : 'primary'}
                            success={success}
                        />
                    }
                    label={label}
                    alignTop={alignTop}
                />
            </FormGroup>
        </ThemedComponent>
    );
};

Check.propTypes = {
    label: PropTypes.string.isRequired,
    title: PropTypes.string,
    checked: PropTypes.bool,
    secondary: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    alignTop: PropTypes.bool,
};

Check.defaultProps = {
    label: '',
    title: '',
    checked: false,
    secondary: false,
    onChange: undefined,
    alignTop: false,
};

export default Check;