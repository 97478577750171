export const optionsStates = [
    { id: 1, title: 'AC', name: 'Acre' },
    { id: 2, title: 'AL', name: 'Alagoas' },
    { id: 3, title: 'AP', name: 'Amapa' },
    { id: 4, title: 'AM', name: 'Amazonas' },

    { id: 5, title: 'BA', name: 'Bahia' },
    { id: 6, title: 'CE', name: 'Ceara' },
    { id: 7, title: 'DF', name: 'Distrito Federal' },
    { id: 8, title: 'ES', name: 'Espirito Santo' },
    { id: 9, title: 'GO', name: 'Goias' },
    { id: 10, title: 'MA', name: 'Maranhão' },

    { id: 11, title: 'MT', name: 'Mato Grosso' },
    { id: 12, title: 'MS', name: 'Mato Grosso do Sul' },
    { id: 13, title: 'MG', name: 'Minas Gerias' },
    { id: 14, title: 'PA', name: 'Pará' },
    { id: 15, title: 'PB', name: 'Paraíba' },

    { id: 16, title: 'PR', name: 'Paraná' },
    { id: 17, title: 'PE', name: 'Pernambuco' },
    { id: 18, title: 'PI', name: 'Piauí' },
    { id: 19, title: 'RJ', name: 'Rio de Janeiro' },

    { id: 20, title: 'RN', name: 'Rio Grande do Norte' },
    { id: 21, title: 'RS', name: 'Rio Grande do Sul' },
    { id: 22, title: 'RO', name: 'Rondônia' },
    { id: 23, title: 'RR', name: 'Roraima' },

    { id: 24, title: 'SC', name: 'Santa Catarina' },
    { id: 25, title: 'SP', name: 'São Paulo' },
    { id: 26, title: 'SE', name: 'Sergipe' },
    { id: 27, title: 'TO', name: 'Tocântins' },
]

export const sortOptions = [
    { id: 'price-asc', title: 'Preço: Menor para Maior' },
    { id: 'price-desc', title: 'Preço: Maior para Menor' },
    { id: 'name-asc', title: 'Nome: A-Z' },
    { id: 'name-desc', title: 'Nome: Z-A' }
];

export const brandOptions = [
    { id: "Jim beam", title: "Jim beam" },
    { id: "Maker’s Mark", title: "Maker’s Mark" },
    { id: "Roku", title: "Roku" },
    { id: "The House of Suntory", title: "The House of Suntory" },
    { id: "Laphroaig", title: "Laphroaig" },
    { id: "Macallan", title: "Macallan" }
];

export const sidebarOptions = [
    {
        id: 1,
        title: "Categorias",
        type:"categories",
        options: [  ]
    },
    {
        id: 2,
        title: "Fornecedor",
        type:"suppliers",
        options: [
            { id:'menimports', title: "MENIMPORTS" },
            { id:'snapzap', title: "SNAPZAP" },
        ]
    }
];

export const options = [
    { id: '1', title: 1 },
    { id: '2', title: 2 },
    { id: '3', title: 3 },
    { id: '4', title: 4 },
    { id: '5', title: 5 },
    { id: '6', title: 6 },
    { id: '7', title: 7 },
    { id: '8', title: 8 },
    { id: '9', title: 9 },
    { id: '10', title: 10 }
];

export const installmentOptions = [
    { id: '1', title: '1x' },
    { id: '2', title: '2x' },
    { id: '3', title: '3x' },
    { id: '4', title: '4x' },
    { id: '5', title: '5x' },
    { id: '6', title: '6x' },
    { id: '7', title: '7x' },
    { id: '8', title: '8x' },
    { id: '9', title: '9x' },
    { id: '10', title: '10x' }
];
export const menuItems = [
    {
        label: 'Home',
        link: '',
    },
    {
        label: 'Sobre nós',
        link: 'about',
    },
    // {
    //     label: 'Minha conta',
    //     link: 'dashboard/Me',
    // },
    // {
    //     label: 'Suporte',
    //     link: 'dashboard/Me',
    // }
];
export const categories = [
    {
        label: 'Categoria 1',
        link: '/categoria-1',
        icon: 'arrow-right'
    },
    {
        label: 'Categoria 2',
        link: '/categoria-2',
        icon: 'arrow-right'
    },
    {
        label: 'Categoria 3',
        link: '/categoria-3',
        icon: 'arrow-right'
    },
    {
        label: 'Categoria 4',
        link: '/categoria-4',
        icon: 'arrow-right'
    },
    {
        label: 'Categoria 5',
        link: '/categoria-5',
        icon: 'arrow-right'
    },
    {
        label: 'Categoria 6',
        link: '/categoria-6',
        icon: 'arrow-right'
    },
    {
        label: 'Categoria 7',
        link: '/categoria-7',
        icon: 'arrow-right'
    },
    {
        label: 'Categoria 8',
        link: '/categoria-8',
        icon: 'arrow-right'
    },
    {
        label: 'Categoria 9',
        link: '/categoria-9',
        icon: 'arrow-right'
    },
    {
        label: 'Categoria 10',
        link: '/categoria-10',
        icon: 'arrow-right'
    }
];
export const contactOptions = [
    {
        label: 'SAC (11) 3030-1234',
        link: 'tel:1130301234',
        icon: 'phone'
    },
    {
        label: 'Whatsapp (11) 98765-4321',
        link: 'https://wa.me/11987654321',
        icon: 'whatsapp'
    }
];
export const icons = [
    // { icon: '/icons/facebook-black.svg', link: 'https://www.facebook.com' },
    // { icon: '/icons/youtube-black.svg', link: 'https://www.youtube.com' },
    // { icon: '/icons/twitter-black.svg', link: 'https://www.twitter.com' },
    { icon: '/icons/instagram-black.svg', link: 'https://www.instagram.com/primespiritsclub/' }
]

export const dateOptions = {
    days: Array.from({ length: 31 }, (v, k) => ({ id: k + 1, title: (k + 1).toString() })),
    months: [
        { id: 1, title: "Janeiro" },
        { id: 2, title: "Fevereiro" },
        { id: 3, title: "Março" },
        { id: 4, title: "Abril" },
        { id: 5, title: "Maio" },
        { id: 6, title: "Junho" },
        { id: 7, title: "Julho" },
        { id: 8, title: "Agosto" },
        { id: 9, title: "Setembro" },
        { id: 10, title: "Outubro" },
        { id: 11, title: "Novembro" },
        { id: 12, title: "Dezembro" }
    ],
    years: Array.from({ length: 101 }, (v, k) => ({ id: new Date().getFullYear() - k, title: (new Date().getFullYear() - k).toString() }))
};


export const suppliers = {
    "snapzap": "SNAPZAP",
    "menimports": "MENIMPORTS",
}