import React, { useMemo, useState } from 'react'
import { FormContent, ProductTitle, ProductinfoContent, Text } from './styled'

// import ShippingDeadline from 'components/ShippingDeadline'
import SellerDetails from 'components/SellerDetails'
import { suppliers } from 'utils/options';
import { parseDiscount } from "utils";


export default function ProductInfo({ price, sale_price, id, title, product, skus }) {


  const [selected, setSelected] = useState(0);

  const [selectedOption, setSelectedOption] = useState(1);

  const handleChange = (qt) => {
    setSelectedOption(qt);
  };

  const sellers = useMemo(() =>
    skus?.length ?
      skus?.map(m => ({
        ...m,
        name: suppliers[m?.source] || "",
        price: m?.sale_price ? m?.sale_price : m?.price,
        discount: parseDiscount(m?.price, m?.sale_price) ? parseDiscount(m?.price, m?.sale_price) : 0,
        originalPrice: m?.price,
        shippingMethod: " ",
      })
      ) : [
        {
          ...product,
          name: suppliers[product?.source] || "",
          price: sale_price ? sale_price : price,
          discount: parseDiscount(price, sale_price) ? parseDiscount(price, sale_price) : 0,
          originalPrice: price,
          // paymentConditions: "ou 12x de R$1.234,56 sem juros",
          shippingMethod: " ",
          // shippingDetails: {
          //   type: "Sedex",
          //   duration: "até 12 dias",
          //   cost: 12.34
          // }
        },
      ], [skus, product]);

  return (
    <>
      <FormContent>
        <ProductinfoContent noSpace>
          <ProductTitle>{title}</ProductTitle>
          {/* <ShippingDeadline /> */}
          {skus?.length ? <Text>Escolha um dos fornecedores abaixo e compre</Text> : null}
          <Text> </Text>
          <SellerDetails sellers={sellers} selected={selected} setSelected={setSelected} />
        </ProductinfoContent>
      </FormContent>

    </>
  )
}
