import React, { useContext, useEffect } from "react";

import { useHistory } from 'react-router-dom';

import {

    DashboardMenuContainer,
    DashboardMenu,
    DashboardMenuHeader,
    DashboardMenuHeaderIcon,

    DashboardMenuOption,
    DashboardMenuContent,
    DashboardMenuFooter,

    DashboardHeader,
    ButtonContent,
    OptionTitle,
    MenuContentItems,
    Title,
    IconList

} from "./styled";

import Button from "components/Form/Button";
import { DoLogout } from "services/authentication";
import { Icon } from "ui/styled";
import useWindowSize from "utils/useWindowSize";
import { brandOptions, categories, contactOptions, icons, menuItems } from "utils/options";
import { CoreContext } from "context/CoreContext";
import { Read } from "services/categories";
import { normalizeStrapiList } from "utils";
import { ReadDepartments, ReadOne } from "services/categories-aditional";

export default function DashboardSide({ opened, setOpened }) {
    const history = useHistory();
    const navigate = to => history.push(`/${to}`);
    const windowSize = useWindowSize();

    const { categories, setCategories, brands, setDepartments, departments } = useContext(CoreContext)

    const verifyClose = e => {
        if (!e.target.closest('.menu-contant')) {
            setOpened(false)
        }
    }

    const exit = async () => {
        await DoLogout()
        navigate('login')
    }

    const handleChange = (brand) => {
        const formattedBrandName = brand?.title?.replace(/\s+/g, '-');
        navigate(`marcas/${formattedBrandName}`)
    };

    const init = async () => {
        const result = await Read()
        const normalResult = normalizeStrapiList(result)

        const departments = await ReadDepartments()
        const normalDepartments = normalizeStrapiList(departments)
        const parsedDepartments = normalDepartments.map(department => ({ ...department, options: normalizeStrapiList(department.category_additionals) }))

        setDepartments(parsedDepartments)
        setCategories(normalResult)
    }

    useEffect(() => { init(); }, [])

    const handleNavigate = (link) => {
        setOpened(false)
        navigate(link)
    }

    return (
        <>
            {
                !opened ? null :
                    <DashboardMenuContainer onClick={verifyClose}>
                        <DashboardMenu>
                            <DashboardMenuHeader >
                                <Icon src="/icons/side-logo.svg" />
                                <DashboardMenuHeaderIcon src="/icons/close.svg" onClick={() => setOpened(false)} />
                            </DashboardMenuHeader>

                            {/* 
                                <DashboardHeader>
                                    <Icon src="/images/frete.svg" />
                                    <ButtonContent>
                                        <Button big between primary noRadius='1px' onClick={() => navigate('dashboard/Me')}>
                                            Minha conta
                                            <Icon src="/icons/profile.svg" />
                                        </Button>
                                        <Button big between primary noRadius='1px' >
                                            Suporte
                                            <Icon src="/icons/support.svg" />
                                        </Button>
                                    </ButtonContent>
                                </DashboardHeader> 
                            */}

                            <DashboardMenuContent>
                                <MenuContentItems>
                                    <Title>Menu</Title>
                                    {
                                        menuItems.map((item, index) => (
                                            <DashboardMenuOption onClick={() => navigate(item.link)}>
                                                <OptionTitle>{item.label}</OptionTitle>
                                                <Icon src="/icons/direction-right.svg" />
                                            </DashboardMenuOption>

                                        ))
                                    }
                                </MenuContentItems>
                                {
                                    windowSize.width > 744 ? null :
                                        <MenuContentItems marginTop>
                                            <Title>Nossas marcas</Title>
                                            {
                                                brands.map((item, index) => (
                                                    <DashboardMenuOption onClick={() => handleChange(item)}>
                                                        <OptionTitle>
                                                            {item.title}
                                                        </OptionTitle>
                                                        <Icon src="/icons/direction-right.svg" />
                                                    </DashboardMenuOption>
                                                ))
                                            }
                                        </MenuContentItems>
                                }
                                <MenuContentItems marginTop>
                                    <Title>Categorias</Title>
                                    {
                                        categories.map((item, index) => (
                                            <DashboardMenuOption onClick={() => handleNavigate(`produtos?category=${item.id}`)}>
                                                <OptionTitle>{item.title}</OptionTitle>
                                                <Icon src="/icons/direction-right.svg" />
                                            </DashboardMenuOption>
                                        ))
                                    }
                                </MenuContentItems>
                                {
                                    departments.map((item, index) => (
                                        <MenuContentItems marginTop>
                                            <Title>{item?.title}</Title>
                                            {
                                                item?.options?.map((subItem, index) => (
                                                    <DashboardMenuOption onClick={() => handleNavigate(`produtos?departments=${item?.id}&option=${subItem?.id}`)}>
                                                        <>
                                                            <OptionTitle>{subItem.title}</OptionTitle>
                                                            <Icon src="/icons/direction-right.svg" />
                                                        </>
                                                    </DashboardMenuOption>
                                                ))
                                            }
                                        </MenuContentItems>
                                    ))
                                }
                                <MenuContentItems marginTop>
                                    <Title>Termos</Title>
                                    <DashboardMenuOption onClick={() => navigate('terms')}>
                                        <OptionTitle>Política de Privacidade</OptionTitle>
                                    </DashboardMenuOption>
                                </MenuContentItems>
                            </DashboardMenuContent>

                            <DashboardMenuFooter>
                                <MenuContentItems white marginTop >
                                    <Title centred>Siga o Prime Spirits no Instagram</Title>
                                    <IconList>
                                        {icons.map((icon, index) => (
                                            <Icon key={index} src={icon.icon} onClick={!icon?.link ? null : () => window.open(icon?.link, "new")} />
                                        ))}
                                    </IconList>
                                </MenuContentItems>

                            </DashboardMenuFooter>

                        </DashboardMenu>
                    </DashboardMenuContainer >
            }
        </>
    );
}