import React, { useEffect, useMemo, useState } from "react";
import {
    BodyContent,
    ButtonLink,
    FormContent,
    ProductContent,
    ProductQuantity,
    TitleContent
} from "./styled";
import ContainerAuthenticated from "containers/Authenticated";
import Footer from "components/Footer";
import { Content, Load, LoadCenter, Title } from "ui/styled";
import Select from "components/Form/Select";
import SideBar from "components/SideBar";
import ProductCard from "components/ProductCard";
import { sortOptions } from "utils/options";
import { drinks } from "utils/drinks";
import PaymentMethods from "components/PaymentMethods";
import Brands from "components/Brands";
import useWindowSize from "utils/useWindowSize";
import { Read } from "services/products";
import { normalizeStrapiList } from "utils";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { ReadAbout } from "services/home";

import ReactMarkdown from 'react-markdown';

export default function About() {
    const location = useLocation();

    const [loading, setLoading] = useState(false)
    
    const [aboutText, setAboutText] = useState("")
    
    const contactEmail = "contato@spiritsclub.com.br"

    const init = async () => {
        const result = await ReadAbout()

        console.log("result", result)
        // setAboutText
        setAboutText(result?.data?.attributes?.about || "")
    }

    useEffect(() => { init() ;}, [])

    return (
        <>
            <ContainerAuthenticated>
                <FormContent>


                    { ( loading ) ? <LoadCenter> <Load black /> </LoadCenter> : <></> } 

                    <ReactMarkdown>{aboutText}</ReactMarkdown>

                </FormContent>
                {/* <Brands /> */}
                <PaymentMethods />
                <Footer />
            </ContainerAuthenticated>
        </>
    );
}