import React, { useEffect, useState } from 'react'
import { Answer, CommonQuestionsContent, CommonTitle, FaqsContainer, Question, QuestionIcon, Text, TextAnswer, TitleContainer } from './styled'
import { MoreInfosContainer } from './styled'
import { Read } from 'services/faq'
import { normalizeStrapiList } from 'utils'
import { Title } from 'ui/styled'


export default function CommonQuestions() {
  const [activeQuestions, setActiveQuestions] = useState([])
  const [faq, setFaq] = useState([])

  const [loading, setLoading] = useState(false)

  const handleQuestionClick = (id) => {
    if (activeQuestions.includes(id)) {
      setActiveQuestions(activeQuestions.filter(questionId => questionId !== id))
    } else {
      setActiveQuestions([...activeQuestions, id])
    }
  }

  const init = async () => {
    if (!loading) {
      setLoading(true)

      const resultFaq = await Read()
      const normalFaq = normalizeStrapiList(resultFaq)
      setFaq(normalFaq?.faq)

      const questions = normalFaq?.map((item) => item?.faq)
      setFaq(questions[0])
      setLoading(false)
    }
  }

  useEffect(() => { init() }, [])


  return (
    <>
      <MoreInfosContainer>
        <TitleContainer><Title> Perguntas Frequentes</Title></TitleContainer>
        <CommonQuestionsContent faq>
          {
            faq?.map((item, key) => (
              <FaqsContainer key={key}>
                <Question faq onClick={() => handleQuestionClick(key)} open={activeQuestions.includes(key)}>
                  <Text faq>{item?.question}</Text>
                  <QuestionIcon faq open={activeQuestions.includes(key)} />
                </Question>
                {
                  activeQuestions.includes(key) && (
                    <Answer faq>
                      <TextAnswer faq>{item?.answer}</TextAnswer>
                    </Answer>
                  )
                }
              </FaqsContainer>

            ))
          }
        </CommonQuestionsContent>
      </MoreInfosContainer>
    </>
  )
}