import React from 'react'
import { useTrackingCode } from 'react-hubspot-tracking-code-hook';

export default function useAnalytics() {
  const { setPathPageView, setTrackEvent } = useTrackingCode();
  // gtag('event', 'test_event_2',
  //   { 'test_parameter': 'hey', 'test_value': 5 })

  const track = (event, properties) => {
    const dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }

    setTrackEvent(event, properties);
    gtag('event', event, properties)
  }

  return {
    track,
  }
}