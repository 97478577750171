import React from 'react';
import {
  ProductDescriptionContent,

  ProductContent,
  Description,
  Specifications,
  SpecificationItem,
  SpecificationLabel,
  SpecificationValue,
  TextDescription
} from './styled';
import { FormContent, Title } from 'ui/styled';



export default function ProductDescription({ product }) {

  const productDetails = {
    description: "",
    specifications: [
      { label: 'Origem', value: (product?.product_specifications?.origin || "") },
      { label: 'Categoria', value: (product?.product_specifications?.category || "") },
      { label: 'Volume', value: (product?.product_specifications?.volume || "") },
      { label: 'Sabor', value: (product?.product_specifications?.flavor || "") },
      { label: 'Aroma', value: (product?.product_specifications?.aroma || "") },
      { label: 'Envelhecido', value: (product?.product_specifications?.aged || "") },
      { label: 'Cor', value: (product?.product_specifications?.color || "") },
      { label: 'Principais ingredientes', value: (product?.product_specifications?.main_ingredients || "") },
    ].filter(f => f?.value)
  };

  return (
    <ProductDescriptionContent>
      <FormContent>
        <ProductContent>
          <Description>
            <Title>Descrição do Produto</Title>
            <TextDescription dangerouslySetInnerHTML={{ __html: product?.summary?.__text }} />
            {/* {productDetails.description.split('\n').map((paragraph, index) => (
              <TextDescription key={index}>{paragraph}</TextDescription>
            ))} */}
          </Description>
          {
            !productDetails?.specifications?.length ? null :
              <Specifications>
                <Title>Especificações do Produto</Title>
                {productDetails.specifications.map((spec, index) => (
                  <SpecificationItem key={index}>
                    <SpecificationLabel>{spec.label}:</SpecificationLabel>
                    <SpecificationValue>{spec.value}</SpecificationValue>
                  </SpecificationItem>
                ))}
              </Specifications>
          }
        </ProductContent>
      </FormContent>
    </ProductDescriptionContent>
  );
}