import styled from "styled-components";


export const SamuelsFamilyImage = styled.img.attrs({
})`
  width: 100%;
  height: auto;
`;

export const FourWTitleContainer = styled.div.attrs({})`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 94px 96px 10px 40px;

  @media (max-width: 1100px) {
    padding: 64px 24px 10px 24px;
  }
`;

export const Subtitle = styled.div.attrs({
})`
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: ${p => p.theme.palette.colors.black};
`;

export const SamuelsCardContainer = styled.div.attrs({
})`
  display: flex;
  gap: 16px;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
  padding: 0 16px;
  position: relative;
  margin-top: 64px;
  @media (max-width: 768px) {
    min-width: 100%;
  }
  
  
`;
export const FourWCardContainer = styled.div.attrs({
})`
  display: flex;
  gap: 16px;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  position: relative;
  margin-top: 64px;
  @media (max-width: 768px) {
    min-width: 100%;
  }
  
  
`;

export const SamuelsBackground = styled.div.attrs({
})`

  background-image: url('/images/backgroundred.png');
  background-size: cover;
  width: 100%;
  height: 224px;
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  right: 0;

`;