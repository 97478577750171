import React from 'react'
import { BrandContainer, BrandTitle, FormContent } from 'ui/styled'
import { FormSpace, HistoryCardContainer, HistoryContainer, Image, IslayContainer, PillarsImage, PillarsImageContainer, SamuelsFamilyImage, Subtitle, Title, TitleContainer, UniqueCardContainer } from './styled'
import History from 'components/History'
import HistoryCard from 'components/Cards/History'
import UniqueCard from 'components/Cards/Unique'



export default function Laphroaig({ brand, productDetails }) {
  const laphroaigHistory = [
    {
      image: '/images/laphroaig1.png',
      years: '1921 -1954',
      title: 'Ian Hunter assumiu a administração da destilaria em 1921 e revitalizou-a. ',
      description: ' Sob Ian, Laphroaig engarrafou seu single malt pela primeira vez em 1923, começou a usar barris ex-bourbon e desenvolveu a receita base do líquido',
    },
    {
      image: '/images/laphroaig2.png',
      years: '1954-1952',
      title: 'Bessie Williamson foi a primeira mulher gerente de destilaria.',
      description: 'Sua paixão e motivação fortaleceram o legado da Laphroaig, impulsionando o crescimento internacional da marca e reforçando seus laços estreitos com a vida e a tradição da Islay.',
    },
    {
      image: '/images/laphroaig3.png',
      years: '1994',
      title: 'Laphroaig recebe o Royal Warrant',
      title: 'Sua Alteza Real o Príncipe Charles  - hoje Rei Charles III, visitou Laphroaig pela primeira vez e deu à destilaria seu Mandado Real.',
    },
  ]

  const uniqueInfo = [
    { title: "Água <br/> turfa", image: '/images/lap1.jpg' },
    { title: "glenmachrie <br/> peat bog", image: '/images/lap2.jpg' },
    { title: "processo de <br/> defunmação a frio", image: '/images/lap3.jpg' },
    { title: "braços de lyne <br/> para cima", image: '/images/lap4.jpg' },
  ]
  return (
    <>
      <BrandContainer brand={brand}>
        <FormContent>
          <Title>A magia da ilha de Islay</Title>
          <IslayContainer>

            <Subtitle noMax>
              Islay, a joia das Ilhas Hébridas Internas da Escócia, é mundialmente reconhecida como o coração pulsante da produção de whisky single malt. Com sua paisagem deslumbrante, rica herança cultural e tradição centenária na destilação, Islay oferece o cenário perfeito para entender a essência que Laphroaig carrega desde 1815;
            </Subtitle>
            <Image src={'/images/islay.png'} alt="Laphroaig" />
          </IslayContainer>
        </FormContent>
      </BrandContainer>
      <HistoryContainer>
        <FormContent>
          <Title>
            A história de Laphroaig
          </Title>
          <HistoryCardContainer>
            {
              laphroaigHistory?.map((item, index) => (
                <HistoryCard key={index} history={item} />
              ))
            }
          </HistoryCardContainer>
        </FormContent>
      </HistoryContainer>
      <FormContent>
        <Title>
          O que torna Laphroaig único
        </Title>
        <Subtitle noMax>
          Seu processo de produção é essencial para conferir a autenticidade e seu caráter único. São 4 pontos que o tornam tão especial.
        </Subtitle>
        <FormSpace />
        <UniqueCardContainer>

          {
            uniqueInfo?.map((item, index) => (
              <UniqueCard key={index} item={item} />
            ))
          }
        </UniqueCardContainer>
      </FormContent>
    </>
  )
}

