import React, { useMemo } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { BreadcrumbContainer, BreadcrumbItem } from './styled';


export default function Breadcrumb() {

  const location = useLocation();
  const { id } = useParams();
  const pathname = location.pathname;
  const pathnames = pathname.split('/').filter((x) => x);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const breadcrumbs = useMemo(() => {
    return [
      { label: 'Home', path: '/' },
      ...pathnames.map((name, index) => {
        const path = `/${pathnames.slice(0, index + 1).join('/')}`; 
        return name === id ? null : { label: capitalizeFirstLetter(name), path } 
      })?.filter(f => f)
    ]
  }, [pathnames]);

  return (
    <BreadcrumbContainer>
      {breadcrumbs.map((item, index) => (
        <BreadcrumbItem key={index}>
          {index !== breadcrumbs.length - 1 ? (
            <Link to={item.path}>{item.label}</Link>
          ) : (
            <span>{item.label}</span>
          )}
        </BreadcrumbItem>
      ))}
    </BreadcrumbContainer>
  );
};

