import React from 'react'
import { FormContent } from 'ui/styled'
import { FlavorProfileContent, FlavorProfileImg, FlavorProfileTitle } from './styled'

export default function FlavorProfile({ flavorImage }) {
  return (
    <>
      <FlavorProfileContent>
        <FormContent>
          <FlavorProfileTitle>Perfil de sabor</FlavorProfileTitle>
          <FlavorProfileImg src={flavorImage} />
        </FormContent>
      </FlavorProfileContent>
    </>
  )
}
